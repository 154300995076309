import React, { useCallback, useMemo } from 'react';
import DataSelect, { DataSelectWrapperProps } from '../dataSelect/DataSelect';
import { ProjectsByCustomersReport } from '../../types/apiDomain';
import getProjectsByCustomers from '../../actions/api/lookup/getProjectsByCustomers';
import { useAppSelector } from '../../hooks/reactRedux';
import { compareStrings } from '../../utils/sorting';

interface Props extends DataSelectWrapperProps {
    internal?: boolean;
    customersFilter?: string[];
}

const ProjectSelect = ({ internal, customersFilter, ...props }: Props) => {
    const projectsByCustomersReport = useAppSelector<ProjectsByCustomersReport | undefined>((state) =>
        internal ? state.dictionaries.internalProjectsByCustomers : state.dictionaries.projectsByCustomers
    );

    const displayableList = useMemo<string[] | undefined>(() => {
        if (!projectsByCustomersReport) {
            return;
        }

        const { projectsByCustomers } = projectsByCustomersReport;

        if (!customersFilter?.length) {
            return Object.values(projectsByCustomers)
                .flat()
                .sort(compareStrings);
        }

        console.log({ projectsByCustomers, customersFilter });

        return customersFilter.flatMap((customer) => projectsByCustomers[customer]).sort(compareStrings);
    }, [projectsByCustomersReport, customersFilter]);

    const fetchData = useCallback(getProjectsByCustomers(internal), [internal]);

    return <DataSelect data={displayableList} fetchData={fetchData} {...props} />;
};

export default ProjectSelect;
