import React, { ReactNode, useMemo } from 'react';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { isNil, isObject } from 'lodash';
import Locale from '../../constants/Locale';

export type RenderCell<T extends object, K extends keyof T> = (value: T[K], object: T, array: T[]) => ReactNode;
export type RenderCells<T extends object> = Partial<{ [K in keyof T]: RenderCell<T, K> }>;

export type RenderColumns<T extends object> = Partial<{ [K in keyof T]: ReactNode }>;

type RenderedRow<T extends object> = { [K in keyof T]: ReactNode };

interface Props<T extends object> {
    data: T[];
    filename: string;
    renderCells?: RenderCells<T>;
    renderColumns?: RenderColumns<T>;
}

export const Renderers = Object.freeze({
    renderDefault: (value: any): ReactNode => (!isObject(value) ? value : String(value)),
    renderNumber: (value: number): ReactNode => value.toLocaleString(Locale.ru_RU)
} as const);

function XlsReportTemplate<T extends object>({ data, filename, renderCells, renderColumns }: Props<T>) {
    const dataKeys = useMemo<Array<keyof T> | undefined>(() => {
        if (renderColumns) {
            return Object.keys(renderColumns) as Array<keyof T>;
        }

        const firstItem = data.find((item) => !isNil(item));
        return firstItem ? (Object.keys(firstItem) as Array<keyof T>) : undefined;
    }, [data, renderColumns]);

    const renderedHeadRows = useMemo<RenderedRow<T> | undefined>(() => {
        if (!dataKeys) {
            return undefined;
        }

        return dataKeys.reduce((result, key) => {
            const renderedCell = renderColumns?.[key] as ReactNode | undefined;
            return {
                ...result,
                [key]: renderedCell ?? <>{key}</>
            };
        }, {} as RenderedRow<T>);
    }, [dataKeys, renderColumns]);

    const renderedRows = useMemo<Array<RenderedRow<T>>>(() => {
        if (!dataKeys) {
            return [];
        }

        return data.map((item) =>
            dataKeys.reduce((result, key) => {
                const render = renderCells?.[key] as RenderCell<T, keyof T> | undefined;
                return {
                    ...result,
                    [key]: render ? render(item[key], item, data) : <>{Renderers.renderDefault(item[key])}</>
                };
            }, {} as RenderedRow<T>)
        );
    }, [data, dataKeys, renderCells]);

    if (!dataKeys) {
        return null;
    }

    return (
        <span style={{ marginLeft: '12px' }}>
            <ReactHTMLTableToExcel
                id="test-table-xls-button"
                className="download-table-xls-button ant-btn"
                table="table-to-xls"
                filename={filename}
                sheet="Отчет"
                buttonText="Скачать как XLS"
            />
            <table id="table-to-xls" style={{ display: 'none' }}>
                <thead>
                    <tr>
                        {renderedHeadRows &&
                            Object.keys(renderedHeadRows).map((key) => (
                                <th key={key}>
                                    <b>{renderedHeadRows[key]}</b>
                                </th>
                            ))}
                    </tr>
                </thead>

                <tbody>
                    {renderedRows.map((row, index) => (
                        <tr key={index}>
                            {Object.keys(row).map((key) => (
                                <td key={key}>{row[key]}</td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </span>
    );
}

export default XlsReportTemplate;
