import { useEffect, useRef } from 'react';

const useFirstEffect: typeof useEffect = (effect, deps) => {
    const wasRenderedRef = useRef(false);

    useEffect(() => {
        const wasRendered = wasRenderedRef.current;
        wasRenderedRef.current = true;
        if (wasRendered) {
            return;
        }

        return effect();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);
};

export default useFirstEffect;
