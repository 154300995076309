import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { actionCreator } from './helpers';
import { ExportLogs, ExportLogsCallback } from '../types/apiDomain';

export interface ExportsState {
    exportLogs?: ExportLogs[];
    exportLogsCallback?: ExportLogsCallback[];
}

const initialState: ExportsState = {};

export const exportLogsLoaded = actionCreator<{ exports: ExportLogs[] }>('EXPORT_LOGS');
export const exportLogsCallbackLoaded = actionCreator<{ exports: ExportLogsCallback[] }>('EXPORT_LOGS_CALLBACK');

export default reducerWithInitialState(initialState)
    .case(exportLogsLoaded, (state, { exports }) => ({
        ...state,
        exportLogs: exports
    }))
    .case(exportLogsCallbackLoaded, (state, { exports }) => ({
        ...state,
        exportLogsCallback: exports
    }));
