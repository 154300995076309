import { combineReducers } from 'redux';
import report, { ReportsState } from './reports';
import dictionaries, { DictionariesState } from './dictionaries';
import exports, { ExportsState } from './exports';
import user, { UserState } from './user';
import issueTemplates, { IssueTemplatesState } from './issueTemplates';

export interface AppState {
    report: ReportsState;
    dictionaries: DictionariesState;
    exports: ExportsState;
    user: UserState;
    issueTemplates: IssueTemplatesState;
}

export default combineReducers<AppState>({
    report,
    dictionaries,
    exports,
    user,
    issueTemplates
});
