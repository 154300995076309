import React, { FunctionComponent } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import store from './reducers/store';
import AppRouter from './AppRouter';

import './App.css';
import AuthProvider from './layout/authProvider/AuthProvider';

const App: FunctionComponent = () => (
    <Provider store={store}>
        <AuthProvider>
            <BrowserRouter>
                <AppRouter />
            </BrowserRouter>
        </AuthProvider>
    </Provider>
);

export default App;
