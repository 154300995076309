import React, { useState } from 'react';
import { Select, Spin } from 'antd';
import { SelectProps } from 'antd/lib/select';
import useFirstEffect from '../../hooks/useFirstEffect';
import { AppThunkAction, useAppDispatch } from '../../hooks/reactRedux';
import displayErrorNotification from '../../utils/ui/displayErrorNotification';
import getErrorMessage from '../../utils/getErrorMessage';

type ValueType = string | number | undefined;

export type ValueExtractor<T> = (item: T) => ValueType;

export interface DataSelectWrapperProps extends SelectProps {}

interface BaseProps<T> extends DataSelectWrapperProps {
    /** Отображаемые данные */
    data: T[] | undefined;
    /** Функция для получения отображаемых данных, передаётся в dispatch внутри компонента */
    fetchData?: AppThunkAction<Promise<unknown>>;
    descriptionExtractor?: ValueExtractor<T>;
    displayableValueExtractor?: ValueExtractor<T>;
}

interface AnyTypeProps<T> extends BaseProps<T> {
    /** Функция для получения отображаемого значения из элемента. По умолчанию - сам элемент.<br>
     *  Обязательный параметр, если сам элемент не может быть отображён */
    valueExtractor: ValueExtractor<T>;
}

interface ValueTypeProps<T extends ValueType> extends BaseProps<T> {
    /** Функция для получения отображаемого значения из элемента. По умолчанию - сам элемент.<br>
     *  Обязательный параметр, если сам элемент не может быть отображён */
    valueExtractor?: ValueExtractor<T>;
}

type DataSelectProps<T> = T extends ValueType ? ValueTypeProps<T> : AnyTypeProps<T>;

/**
 * Отображает выпадающий список (combobox, select), получая данные из Redux store
 */
function DataSelect<T>({
    data,
    fetchData,
    valueExtractor,
    displayableValueExtractor,
    descriptionExtractor,
    ...props
}: DataSelectProps<T>) {
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState(Boolean(!data && fetchData));
    const [hasError, setHasError] = useState(false);

    useFirstEffect(() => {
        if (!fetchData || data) {
            return;
        }

        dispatch(fetchData)
            .then(() => setHasError(false))
            .catch((error) => {
                setHasError(true);
                displayErrorNotification(getErrorMessage(error));
            })
            .finally(() => setIsLoading(false));
    }, [fetchData, data]);

    return (
        <Spin spinning={!data && isLoading}>
            <Select {...props} disabled={hasError}>
                {data?.map((item, index) => {
                    const value = (valueExtractor ? valueExtractor(item) : item) as ValueType;
                    const displayableValue = displayableValueExtractor ? displayableValueExtractor(item) : value;
                    const description = descriptionExtractor?.(item);

                    return (
                        <Select.Option value={value} key={index} title={description?.toString()}>
                            {displayableValue}
                        </Select.Option>
                    );
                })}
            </Select>
        </Spin>
    );
}

export default DataSelect;
