import { AppThunkAction } from '../../../hooks/reactRedux';
import { post } from '../../request';
import { IssueTemplateEntity, IssueTemplateEdit, IssueTemplateEntityRaw } from '../../../types/apiDomain';
import { issueTemplateCreated } from '../../../reducers/issueTemplates';
import getIssueTemplateEntityFromRaw from '../../../utils/getIssueTemplateEntityFromRaw';

const createIssueTemplate = (templateEdit: IssueTemplateEdit): AppThunkAction<Promise<IssueTemplateEntity>> => async (
    dispatch
) => {
    const templateRaw = await post<IssueTemplateEntityRaw>({
        url: '/api/issue-template',
        data: templateEdit
    });

    const template = getIssueTemplateEntityFromRaw(templateRaw);

    dispatch(issueTemplateCreated({ template }));
    return template;
};

export default createIssueTemplate;
