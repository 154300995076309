import React, { PureComponent } from 'react';
import { Descriptions, Collapse, Table, Empty, List, Spin, Button } from 'antd';
import { connect } from 'react-redux';

import styles from './ReportByIdPage.module.css';
import { AppState } from '../../reducers/rootReducer';
import { PageProps } from '../../types/page';

import getReportsById from '../../actions/api/getReportsById';
import FileLink from '../../components/fileLink/FileLink';
import getReportByProject from '../../actions/api/getReportByProject';
import moment from 'moment';
import AppPageHeader from '../../components/appPageHeader/AppPageHeader';
import { ISO_DATE_FORMAT, RU_DATE_FORMAT } from '../../constants/dateFormat';

const { Panel } = Collapse;

interface ReportByIdPageParams {
    reportId?: string;
}

interface Props extends PageProps<{}, ReportByIdPageParams> {
    getReportByIdAction(id: string): Promise<any>;
    getReportByProjectAction(data: object): Promise<any>;
    reportState?: any;
    reportId?: string;
}

interface State {
    hasError?: boolean;
    isLoaded?: boolean;
    isLoadedRecreate?: boolean;
}

const columns = [
    { title: 'id', dataIndex: 'id', key: 'id' },
    { title: 'num', dataIndex: 'num', key: 'num' },
    { title: 'desc', dataIndex: 'desc', key: 'desc' },
    { title: 'url', dataIndex: 'url', key: 'url' },
    { title: 'amount', dataIndex: 'amount', key: 'amount' },
    { title: 'sum', dataIndex: 'sum', key: 'sum' },
    { title: 'fakeAmount', dataIndex: 'fakeAmount', key: 'fakeAmount' },
    { title: 'fakeSum', dataIndex: 'fakeSum', key: 'fakeSum' },
    { title: 'price', dataIndex: 'price', key: 'price' },
    { title: 'fake', dataIndex: 'fake', key: 'fake' }
];

class ReportByIdPage extends PureComponent<Props, State> {
    state: State = {
        isLoaded: false,
        isLoadedRecreate: false
    };
    componentDidMount(): void {
        const { reportId } = this.props;
        this.setState({
            isLoaded: true
        });
        if (reportId) {
            this.props.getReportByIdAction(reportId).finally(() => {
                this.setState({
                    isLoaded: false
                });
            });
        }
    }

    renderFilesItem = (item) => (
        <List.Item>
            <FileLink key={item} fileName={item} />
        </List.Item>
    );

    handleRecreateReport = () => {
        const { reportState, getReportByProjectAction } = this.props;
        const filterParams = {
            projectId: reportState.project,
            reportDate: moment(reportState.date, RU_DATE_FORMAT).format(ISO_DATE_FORMAT),
            startDate: moment(reportState.dateStart, RU_DATE_FORMAT).format(ISO_DATE_FORMAT),
            endDate: moment(reportState.dateEnd, RU_DATE_FORMAT).format(ISO_DATE_FORMAT)
        };

        this.setState({
            isLoadedRecreate: true,
            isLoaded: true
        });
        getReportByProjectAction(filterParams)
            .then(() => {
                this.props.getReportByIdAction(reportState.id).finally(() => {
                    this.setState({
                        isLoaded: false
                    });
                });
            })
            .catch(() => this.setState({ hasError: true }))
            .finally(() => {
                this.setState({ isLoadedRecreate: false });
            });
    };

    render() {
        const { reportState } = this.props;
        const { isLoaded, isLoadedRecreate } = this.state;

        return (
            <div className={styles.wrapper}>
                <AppPageHeader
                    title="Отчет по проекту"
                    extra={
                        reportState && (
                            <Button onClick={this.handleRecreateReport} icon="sync" loading={isLoadedRecreate} />
                        )
                    }
                />
                {isLoaded && <Spin />}

                {!isLoaded && reportState && (
                    <Collapse defaultActiveKey={['1']}>
                        <Panel header="Report Info" key="1">
                            <Descriptions title="Report Info">
                                <Descriptions.Item label="id">{reportState.id}</Descriptions.Item>
                                <Descriptions.Item label="number">{reportState.number}</Descriptions.Item>
                                <Descriptions.Item label="project">{reportState.project}</Descriptions.Item>
                                <Descriptions.Item label="rate">{reportState.rate}</Descriptions.Item>
                                <Descriptions.Item label="activity">{reportState.activity}</Descriptions.Item>
                                <Descriptions.Item label="date">{reportState.date}</Descriptions.Item>
                                <Descriptions.Item label="dateStart">{reportState.dateStart}</Descriptions.Item>
                                <Descriptions.Item label="dateEnd">{reportState.dateEnd}</Descriptions.Item>
                                <Descriptions.Item label="amount">{reportState.amount}</Descriptions.Item>
                                <Descriptions.Item label="total">{reportState.total}</Descriptions.Item>
                                <Descriptions.Item label="fakeAmount">{reportState.fakeAmount}</Descriptions.Item>
                                <Descriptions.Item label="fakeTotal">{reportState.fakeTotal}</Descriptions.Item>
                                <Descriptions.Item label="totalInWords">{reportState.totalInWords}</Descriptions.Item>
                            </Descriptions>
                        </Panel>
                        <Panel header="Items" key="2">
                            {reportState.items ? <Table columns={columns} dataSource={reportState.items} /> : <Empty />}
                        </Panel>
                        <Panel header={'Files'} key={'3'}>
                            {reportState.files ? (
                                <List dataSource={reportState.files} renderItem={this.renderFilesItem} />
                            ) : (
                                <Empty />
                            )}
                        </Panel>
                    </Collapse>
                )}
            </div>
        );
    }
}

const mapState = (state: AppState, { params = {} }: PageProps<ReportByIdPageParams>) => {
    const { reportId } = params;
    return {
        reportState: state.report.reportById,
        reportId
    };
};

const mapActions = (dispatch) => ({
    getReportByIdAction: (id) => Promise.all([dispatch(getReportsById(id))]),
    getReportByProjectAction: (dataFilter) => Promise.all([dispatch(getReportByProject(dataFilter))])
});

export default connect(mapState, mapActions)(ReportByIdPage);
