import request from '../../request';
import { reportVacationLoaded } from '../../../reducers/reports';
import { ReportVac } from '../../../types/apiDomain';

export default ({ employeeId }) => (dispatch) => {
    request<ReportVac>({
        url: `/api/lookup/vacation/${employeeId}`
    }).then((vacationDays) => {
        return dispatch(reportVacationLoaded({ report: vacationDays }));
    });
};
