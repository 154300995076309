import React, { FunctionComponent, useMemo } from 'react';
import { ProjectsReport } from '../../types/apiDomain';
import XlsReportTemplate, { RenderCells, RenderColumns, Renderers } from '../xlsReportTemplate/XlsReportTemplate';
import { minutesToHours } from '../../utils/timeConversion';

interface Data {
    customer: string;
    project: string;
    projectAmount: number;
    issue: string;
    issueAmount: number;
    employeeUsername: string;
    employeeFirstName: string;
    employeeLastName: string;
    employeeAmount: number;
    workLogDate: string;
    workLogAmount: number;
    workLogComment: string;
}

const renderColumns: RenderColumns<Data> = {
    customer: 'Заказчик',
    project: 'Проект',
    projectAmount: 'Сумма по проекту',
    issue: 'Задача',
    issueAmount: 'Сумма по задаче',
    employeeUsername: 'Сотрудник',
    employeeFirstName: 'Имя',
    employeeLastName: 'Фамилия',
    employeeAmount: 'Сумма по сотруднику',
    workLogDate: 'Дата',
    workLogAmount: 'Списанное время',
    workLogComment: 'Комментарий'
};

const renderCells: RenderCells<Data> = {
    projectAmount: Renderers.renderNumber,
    issueAmount: Renderers.renderNumber,
    employeeAmount: Renderers.renderNumber,
    workLogAmount: Renderers.renderNumber
};

interface Props {
    tableData: ProjectsReport;
}

const ProjectsReportXlsExport: FunctionComponent<Props> = ({ tableData }) => {
    const data = useMemo<Data[]>(
        () =>
            tableData.customers.flatMap((customer) =>
                customer.projects.flatMap((project) =>
                    project.issues.flatMap((issue) =>
                        issue.employees.flatMap((employee) =>
                            employee.worklogs.map((worklog) => ({
                                customer: customer.id,
                                project: project.id,
                                projectAmount: project.amount,
                                issue: issue.id,
                                issueAmount: issue.amount,
                                employeeUsername: employee.name,
                                employeeFirstName: employee.firstName,
                                employeeLastName: employee.lastName,
                                employeeAmount: employee.amount,
                                workLogDate: worklog.date,
                                workLogAmount: minutesToHours(worklog.minutes),
                                workLogComment: worklog.comment
                            }))
                        )
                    )
                )
            ),
        [tableData]
    );

    return (
        <XlsReportTemplate data={data} filename="projects" renderColumns={renderColumns} renderCells={renderCells} />
    );
};

export default React.memo(ProjectsReportXlsExport);
