import { reportByEmployeesLoaded } from '../../../reducers/reports';
import { projectsByCustomersLoader } from '../../../reducers/dictionaries';
import requestGetReportByEmployees from '../requests/requestGetReportByEmployees';
import { ReportParams } from '../requests/requestReport';
import { AppDispatch } from '../../../hooks/reactRedux';

const getReportByEmployees = (params: ReportParams) => async (dispatch: AppDispatch) => {
    const reportByEmployees = await requestGetReportByEmployees(params);
    const projectsByCustomers = reportByEmployees.projectsByCustomers;

    dispatch(projectsByCustomersLoader({ list: projectsByCustomers }));
    dispatch(reportByEmployeesLoaded({ report: reportByEmployees }));
};

export default getReportByEmployees;
