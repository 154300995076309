import React, { FunctionComponent, useMemo, useState } from 'react';
import { IssueStats } from '../../../types/apiDomain';
import { Table } from 'antd';
import { getReportByEmployeesWorklogs } from '../../reportByEmployeesTable/components/ReportByEmployeesWorklogs';
import { getRowKey } from '../../../utils/reportUtils';
import styles from '../ReportByEmployeesCalendar.module.scss';
import getCalendarHoursColumn from '../utils/getCalendarHoursColumn';
import { amountSort } from '../../../utils/reportSorting';
import { useCustomExpandColumn } from '../../../hooks/useCustomExpandColumn';
import { Moment } from 'moment';
import { EmployeeCalendarIssue } from '../../../types/reportTypes';

const { Column } = Table;

interface Props {
    issues: Array<IssueStats | EmployeeCalendarIssue>;
    dates: Moment[];
    holidays: string[];
}

const ReportByEmployeesCalendarIssues: FunctionComponent<Props> = ({ issues, dates, holidays }) => {
    const [expandedKeys, setExpandedKeys] = useState<string[]>([]);

    const allAvailableKeys = useMemo<string[]>(() => issues.map(getRowKey), [issues]);

    const expandColumn = useCustomExpandColumn(issues, allAvailableKeys, expandedKeys, setExpandedKeys);

    return (
        <Table
            dataSource={issues}
            expandedRowRender={getReportByEmployeesWorklogs}
            expandRowByClick
            expandedRowKeys={expandedKeys}
            expandIconAsCell={false}
            expandIconColumnIndex={-1}
            pagination={false}
            size={'middle'}
            rowKey={getRowKey}
        >
            {expandColumn}
            <Column
                title={'Задача'}
                dataIndex={'desc'}
                key={'desc'}
                className={styles.nameColumn}
                render={(desc, record: IssueStats) => (
                    <a target="_blank" rel="noopener noreferrer" href={record.url}>
                        {desc}
                    </a>
                )}
            />

            {dates.map((date, index) => {
                return getCalendarHoursColumn<IssueStats>(date, index, holidays, ({ worklogs }) => worklogs);
            })}

            <Column
                title={'Итого'}
                dataIndex={'amount'}
                key={'amount'}
                align={'center'}
                sorter={amountSort}
                className={styles.totalColumn}
            />
        </Table>
    );
};

export default ReportByEmployeesCalendarIssues;
