import { AppThunkAction, useAppDispatch } from './reactRedux';
import { useState } from 'react';
import useFirstEffect from './useFirstEffect';
import getErrorMessage from '../utils/getErrorMessage';

function useDataFetching(loadedData: unknown, thunkAction: AppThunkAction<Promise<unknown>>) {
    const dispatch = useAppDispatch();

    const [isLoading, setIsLoading] = useState<boolean>(!loadedData);
    const [error, setError] = useState<string>();

    useFirstEffect(() => {
        if (loadedData) {
            return;
        }

        setIsLoading(true);
        setError(undefined);
        dispatch(thunkAction)
            .then(() => setError(undefined))
            .catch((error) => setError(getErrorMessage(error)))
            .finally(() => setIsLoading(false));
    }, [loadedData, thunkAction]);

    return {
        isLoading,
        error
    };
}

export default useDataFetching;
