import React, { FunctionComponent } from 'react';
import { PageHeader } from 'antd';
import { PageHeaderProps } from 'antd/lib/page-header';
import classNames from 'classnames';
import styles from './AppPageHeader.module.css';

const AppPageHeader: FunctionComponent<PageHeaderProps> = ({ className, ...props }) => {
    return <PageHeader className={classNames(styles.component, className)} {...props} />;
};

export default AppPageHeader;
