import { applyMiddleware, createStore as createReduxStore, Middleware } from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import rootReducer from './rootReducer';
import { isLogsEnabled } from '../constants/env';
import { composeWithDevTools } from 'redux-devtools-extension';

export default createReduxStore(rootReducer, composeWithDevTools(applyMiddleware(...getMiddlewares())));

function getMiddlewares() {
    const middlewares: Middleware[] = [thunk];
    if (isLogsEnabled) {
        middlewares.push(
            createLogger({
                level: 'info'
            })
        );
    }
    return middlewares;
}
