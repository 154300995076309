import request from '../request';
import { exportLogsCallbackLoaded } from '../../reducers/exports';
import { ExportLogsCallback } from '../../types/apiDomain';

export default () => (dispatch) => {
    request<ExportLogsCallback[]>({
        url: `/api/export/logs/callback`
    }).then((exportsLogsCallback) => {
        return dispatch(exportLogsCallbackLoaded({ exports: exportsLogsCallback }));
    });
};
