import React, { FunctionComponent, useMemo, useState } from 'react';
import { Table } from 'antd';
import { getRowKey } from '../../../utils/reportUtils';
import { amountSort, idSort } from '../../../utils/reportSorting';
import { EmployeeStats } from '../../../types/apiDomain';
import { getReportByEmployeesIssues } from './ReportByEmployeesIssues';
import { useCustomExpandColumn } from '../../../hooks/useCustomExpandColumn';

const { Column } = Table;

interface Props {
    entry: EmployeeStats;
}

export function getReportByEmployeesProjects(entry: EmployeeStats) {
    return <ReportByEmployeesProjects entry={entry} />;
}

const ReportByEmployeesProjects: FunctionComponent<Props> = ({ entry }) => {
    const [expandedKeys, setExpandedKeys] = useState<string[]>([]);

    const allAvailableKeys = useMemo<string[]>(() => entry.projects.map(getRowKey) ?? [], [entry]);

    const expandColumn = useCustomExpandColumn(entry.projects, allAvailableKeys, expandedKeys, setExpandedKeys);

    return (
        <Table
            dataSource={entry.projects}
            pagination={false}
            rowKey={getRowKey}
            key={'projectsRowRender'}
            expandedRowRender={getReportByEmployeesIssues}
            expandRowByClick
            expandedRowKeys={expandedKeys}
            expandIconAsCell={false}
            expandIconColumnIndex={-1}
        >
            {expandColumn}
            <Column title={'project'} dataIndex={'id'} key={'id'} sorter={idSort} />
            <Column title={'amount'} dataIndex={'amount'} key={'amount'} sorter={amountSort} />
            <Column title={'qualityMarkers'} dataIndex={'qualityMarkers'} key={'qualityMarkers'} />
        </Table>
    );
};

export default ReportByEmployeesProjects;
