import request from '../../request';
import { internalProjectListLoader, projectListLoader } from '../../../reducers/dictionaries';
import { ContractedProject } from '../../../types/apiDomain';

const getProjects = (isInternal: boolean = false) => async (dispatch) => {
    const list = await request<ContractedProject[]>({
        url: `/api/lookup/projects${isInternal ? '/internal' : ''}`
    });

    if (isInternal) {
        dispatch(internalProjectListLoader({ list }));
    } else {
        dispatch(projectListLoader({ list }));
    }
};

export default getProjects;
