import moment, { MomentInput } from 'moment';
import { every, isBoolean, isNil, values } from 'lodash';
import BoolEnum, { booleanFromNumber } from '../types/BoolEnum';
import ObjectKey from '../types/common/ObjectKey';

type Matcher<V, Q = V> = (value: V, query: Q | undefined) => boolean;

const NUMBERS_ACCURACY = 0.0000001;

abstract class QueryMatching {
    static match = (matches: Record<ObjectKey, boolean> | boolean[] | boolean) => {
        return isBoolean(matches) ? matches : every(values(matches));
    };

    static matchString: Matcher<string> = (value, query) => {
        if (!query?.trim()) {
            return true;
        }

        return value.toLocaleLowerCase().includes(query.toLocaleLowerCase().trim());
    };

    static matchNumber: Matcher<number> = (value, query) => {
        if (isNil(query)) {
            return true;
        }

        return value === query;
    };

    static matchFloatNumber: Matcher<number> = (value, query) => {
        if (isNil(query)) {
            return true;
        }

        return Math.abs(value - query) <= NUMBERS_ACCURACY;
    };

    static matchBoolean: Matcher<boolean> = (value, query) => {
        if (isNil(query)) {
            return true;
        }

        return value === query;
    };

    static matchNumericBoolean: Matcher<boolean, BoolEnum | number> = (value, query) => {
        return QueryMatching.matchBoolean(value, booleanFromNumber(query));
    };

    static matchDateTime: Matcher<MomentInput> = (value, query) => {
        if (!query) {
            return true;
        }

        return moment(value).isSame(query);
    };

    static matchDate: Matcher<MomentInput> = (value, query) => {
        if (!query) {
            return true;
        }

        return moment(value).isSame(query, 'day');
    };

    static matchDateRange: Matcher<MomentInput, [MomentInput | undefined, MomentInput | undefined]> = (
        value,
        query
    ) => {
        if (!query || query.every(isNil)) {
            return true;
        }

        return moment(value).isBetween(query[0], query[1], 'day');
    };
}

export default QueryMatching;
