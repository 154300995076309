import { CreateIssueByTemplateRequest, IssueCreationResult, IssueTemplateEntity } from '../../../types/apiDomain';
import { post } from '../../request';

const createIssueFromTemplate = (
    templateId: IssueTemplateEntity['id'],
    data: CreateIssueByTemplateRequest
): Promise<IssueCreationResult> => {
    return post<IssueCreationResult>({
        url: `/api/issue-template/${templateId}/create-issue`,
        data
    });
};

export default createIssueFromTemplate;
