const isNumberInRange = (number: number, from: number, to: number) => {
    return number >= from && number < to;
};

abstract class ResponseCode {
    static is1XX(code: number) {
        return isNumberInRange(code, 100, 200);
    }
    static is2XX(code: number) {
        return isNumberInRange(code, 200, 300);
    }
    static is3XX(code: number) {
        return isNumberInRange(code, 300, 400);
    }
    static is4XX(code: number) {
        return isNumberInRange(code, 400, 500);
    }
    static is5XX(code: number) {
        return isNumberInRange(code, 500, 600);
    }
    static isError(code: number) {
        return ResponseCode.is4XX(code) || ResponseCode.is5XX(code);
    }
}

export default ResponseCode;
