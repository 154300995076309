import moment, { Moment } from 'moment';

/**
 * Производит валидацию даты списания. Списание возможно только за текущую неделю.
 */
const validateWorkLogDate = (date: Moment): boolean => {
    const currentDate = moment();

    const weekStart = currentDate.clone().startOf('isoWeek');
    const weekEnd = currentDate.clone().endOf('isoWeek');

    return date.isSameOrAfter(weekStart, 'day') && date.isSameOrBefore(weekEnd, 'day');
};

export default validateWorkLogDate;
