import React, { ReactElement, ReactNode, Ref, useCallback, useMemo, useState } from 'react';
import { Collapse, Descriptions, Spin } from 'antd';
import styles from './ReportPageTemplate.module.css';
import ReportByEmployeesFilter from '../../forms/reportByEmployeesFilter/ReportByEmployeesFilter';
import AppPageHeader from '../appPageHeader/AppPageHeader';
import { AppDispatch, useAppDispatch } from '../../hooks/reactRedux';
import { ReportParams } from '../../actions/api/requests/requestReport';
import getErrorMessage from '../../utils/getErrorMessage';
import ErrorResult from '../errorResult/ErrorResult';
import { Report } from '../../types/apiDomain';

const { Panel } = Collapse;

interface Props<T extends Report> {
    title: string;
    report: T | undefined;
    fetchReport: (params: ReportParams) => (dispatch: AppDispatch) => Promise<void>;
    countLabel: string;
    countValueExtractor: (report: T) => number;
    exportNodeGetter: (report: T) => ReactNode;
    tableGetter: (report: T) => ReactNode;
    renderAfterInfoPanel?: (report?: T) => ReactNode;
    renderAfterMainPanel?: (report?: T) => ReactNode;
}

const ReportPageTemplate = React.forwardRef(
    <ReportType extends Report>(props: Props<ReportType>, ref: Ref<HTMLDivElement>) => {
        const {
            title,
            report,
            fetchReport,
            countLabel,
            countValueExtractor,
            exportNodeGetter,
            tableGetter,
            renderAfterInfoPanel,
            renderAfterMainPanel
        } = props;

        const dispatch = useAppDispatch();

        const [isLoading, setIsLoading] = useState<boolean>(true);
        const [error, setError] = useState<string>();

        const countValue = useMemo(() => report && countValueExtractor(report), [countValueExtractor, report]);

        const exportNode = useMemo(() => report && exportNodeGetter(report), [exportNodeGetter, report]);

        const tableNode = useMemo(() => report && tableGetter(report), [report, tableGetter]);

        const afterInfoPanel = useMemo(() => renderAfterInfoPanel?.(report), [report, renderAfterInfoPanel]);

        const afterMainPanel = useMemo(() => renderAfterMainPanel?.(report), [report, renderAfterMainPanel]);

        const createReport = useCallback(
            async (filterParams: ReportParams) => {
                setIsLoading(true);
                setError(undefined);
                try {
                    await dispatch(fetchReport(filterParams));
                } catch (error) {
                    setError(getErrorMessage(error));
                } finally {
                    setIsLoading(false);
                }
            },
            [dispatch, fetchReport]
        );

        return (
            <div className={styles.wrapper} ref={ref}>
                <AppPageHeader title={title} />

                <ReportByEmployeesFilter submitFilter={createReport} exportNode={exportNode} />

                {error ? (
                    <ErrorResult error={error} />
                ) : (
                    <Spin spinning={isLoading}>
                        <Collapse defaultActiveKey={['info']}>
                            <Panel header="Информация об отчёте" key="info">
                                <Descriptions title="Report Info" column={4}>
                                    <Descriptions.Item label="amount">{report?.amount}</Descriptions.Item>
                                    <Descriptions.Item label={countLabel}>{countValue}</Descriptions.Item>
                                    <Descriptions.Item label="hoursForPeriod">
                                        {report?.hoursForPeriod ?? 'Н/Д'}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="maxTotalHoursForPeriod">
                                        {report?.maxTotalHoursForPeriod ?? 'Н/Д'}
                                    </Descriptions.Item>
                                </Descriptions>
                            </Panel>
                            {afterInfoPanel}
                            <Panel header="Items" key="main">
                                {tableNode}
                            </Panel>
                            {afterMainPanel}
                        </Collapse>
                    </Spin>
                )}
            </div>
        );
    }
);

export default ReportPageTemplate as <ReportType extends Report>(
    props: Props<ReportType> & { ref?: Ref<HTMLDivElement> }
) => ReactElement;
