import React, { FunctionComponent } from 'react';
import { Result } from 'antd';
import { ResultProps } from 'antd/lib/result';

interface Props extends Omit<ResultProps, 'status' | 'subTitle'> {
    error: string;
}

const ErrorResult: FunctionComponent<Props> = ({ error, title, ...props }) => (
    <Result status="error" title={title ?? 'Произошла ошибка'} subTitle={error} {...props} />
);

export default React.memo(ErrorResult);
