import { AppThunkAction } from '../../../hooks/reactRedux';
import request from '../../request';
import { IssueTemplateVariablesInfo } from '../../../types/apiDomain';
import { issueTemplateVariablesInfoLoaded } from '../../../reducers/issueTemplates';

const getVariablesInfo = (): AppThunkAction<Promise<void>> => async (dispatch) => {
    const variablesInfo = await request<IssueTemplateVariablesInfo>({
        url: '/api/issue-template/variables'
    });

    dispatch(issueTemplateVariablesInfoLoaded({ variablesInfo }));
};

export default getVariablesInfo;
