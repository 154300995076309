import React, { FunctionComponent, useCallback, useState } from 'react';
import getCurrentUser from '../../actions/api/getCurrentUser';
import { Spin } from 'antd';
import styles from './AuthProvider.module.css';
import useAuthSelector from '../../hooks/useAuthSelector';
import { useAppDispatch } from '../../hooks/reactRedux';
import useFirstEffect from '../../hooks/useFirstEffect';
import ApiError, { createApiError } from '../../types/ApiError';
import ErrorResult from '../../components/errorResult/ErrorResult';

const AuthProvider: FunctionComponent = ({ children }) => {
    const dispatch = useAppDispatch();
    const { user } = useAuthSelector();
    const [isLoading, setIsLoading] = useState<boolean>(user === undefined);
    const [error, setError] = useState<ApiError>();

    const fetchCurrentUser = useCallback(async () => {
        setIsLoading(true);
        setError(undefined);
        try {
            await dispatch(getCurrentUser());
        } catch (error) {
            setError(createApiError(error));
        } finally {
            setIsLoading(false);
        }
    }, [dispatch]);

    useFirstEffect(() => {
        if (!user) {
            fetchCurrentUser();
        }
    }, [fetchCurrentUser, user]);

    if (error?.isAuthError === false) {
        return <ErrorResult error={error.error} />;
    }

    if (isLoading || error?.isAuthError) {
        return <Spin spinning size="large" className={styles.loading} />;
    }

    return <>{children}</>;
};

export default AuthProvider;
