import React, { FunctionComponent, ReactNode } from 'react';
import {
    CustomerProjectStats,
    CustomerStats,
    IssueEmployeeStats,
    ProjectIssueStats,
    ProjectsReport,
    WorkLogDto
} from '../../../../types/apiDomain';
import { Table } from 'antd';
import { ExpandedRowRender } from '../../../../types/antd';
import { ColumnProps } from 'antd/lib/table';
import { minutesToHours } from '../../../../utils/timeConversion';
import styles from './ReportByProjectsTable.module.css';
import { compareFields, compareNumbers, compareStrings } from '../../../../utils/sorting';

const getRowKey = (entry, index) => {
    return `row_${entry.id ?? entry.name ?? index}`;
};

interface Props {
    tableData: ProjectsReport;
}

const ReportByProjectsTable: FunctionComponent<Props> = ({ tableData }) => (
    <>
        {createTable({
            dataSource: tableData.customers,
            columns: rootTableColumns,
            expandedRowRender: expandedCustomerRowRender
        })}
    </>
);

export default React.memo(ReportByProjectsTable);

//region EXPANDED_ROW_RENDER
const expandedCustomerRowRender: ExpandedRowRender<CustomerStats> = (customer) =>
    createTable({
        dataSource: customer.projects,
        columns: getIdAmountColumns('Проект'),
        expandedRowRender: expandedProjectRowRender
    });

const expandedProjectRowRender: ExpandedRowRender<CustomerProjectStats> = (project) =>
    createTable({
        dataSource: project.issues,
        columns: getIdAmountColumns('Задача'),
        expandedRowRender: expandedIssueRowRender
    });

const expandedIssueRowRender: ExpandedRowRender<ProjectIssueStats> = (issue) =>
    createTable({
        dataSource: issue.employees,
        columns: employeeColumns,
        expandedRowRender: expandedEmployeeRowRender
    });

const expandedEmployeeRowRender: ExpandedRowRender<IssueEmployeeStats> = (employee) =>
    createTable({
        dataSource: employee.worklogs,
        columns: worklogColumns
    });
//endregion

interface CreateTableParams<T> {
    dataSource: T[];
    columns: ColumnProps<T>[];
    expandedRowRender?: ExpandedRowRender<T>;
}

const createTable = <T,>({ dataSource, columns, expandedRowRender }: CreateTableParams<T>): ReactNode => (
    <Table
        dataSource={dataSource}
        pagination={false}
        rowKey={getRowKey}
        columns={columns}
        expandedRowRender={expandedRowRender}
    />
);

//region COLUMNS
const createColumn = (field: string, title?: string): ColumnProps<any> => ({
    title: title ?? field,
    dataIndex: field,
    key: field
});

const createIdColumn = (title?: string): ColumnProps<any> => ({
    ...createColumn('id', title ?? 'ID'),
    sortDirections: ['ascend', 'descend'],
    sorter: compareFields('id', compareStrings)
});

const amountColumn: ColumnProps<any> = {
    ...createColumn('amount', 'Сумма'),
    sortDirections: ['ascend', 'descend'],
    sorter: compareFields('amount', compareNumbers)
};

const getIdAmountColumns = (title?: string): ColumnProps<any>[] => [createIdColumn(title), amountColumn];

const employeeColumns: ColumnProps<IssueEmployeeStats>[] = [
    createColumn('name', 'Сотрудник'),
    createColumn('firstName', 'Имя'),
    createColumn('lastName', 'Фамилия'),
    amountColumn
];

const worklogColumns: ColumnProps<WorkLogDto>[] = [
    createColumn('date', 'Дата'),
    {
        ...createColumn('comment', 'Комментарий'),
        className: styles.textPreWrap
    },
    {
        ...createColumn('minutes', 'Время'),
        render: (text, { minutes }) => minutesToHours(minutes)
    }
];

const rootTableColumns: ColumnProps<CustomerStats>[] = [
    {
        ...createIdColumn('Заказчик')
    },
    {
        ...amountColumn
    }
];
//endregion
