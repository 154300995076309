import React, { ComponentType, PureComponent } from 'react';
import styles from './VacationPage.module.css';
import { Button, Form, Select } from 'antd';
import { FormComponentProps } from 'antd/lib/form/Form';
import { connect } from 'react-redux';
import { User } from '../../types/apiDomain';
import { AppState } from '../../reducers/rootReducer';
import getVacationDay from '../../actions/api/lookup/getVacationDay';
import { Typography } from 'antd';
import AppPageHeader from '../../components/appPageHeader/AppPageHeader';

const { Title } = Typography;

interface Props extends FormComponentProps {
    employeeList: User[];
    reportVacation?: number;
    getReportVacationAction(data: object): Promise<any>;
}

interface State {
    hasError?: boolean;
    isLoading?: boolean;
}

function hasErrors(fieldsError) {
    return Object.keys(fieldsError).some((field) => fieldsError[field]);
}

const { Option } = Select;

class VacationPage extends PureComponent<Props, State> {
    state: State = {};

    handleSubmit = (e) => {
        const { getReportVacationAction } = this.props;
        e.preventDefault();

        this.setState({ isLoading: true });

        this.props.form.validateFields((err, fieldsValue) => {
            if (err) {
                return;
            }

            const values = {
                employeeId: fieldsValue['employees']
            };

            getReportVacationAction(values)
                .catch(() => this.setState({ hasError: true }))
                .finally(() => this.setState({ isLoading: false }));
        });
    };

    render() {
        const { getFieldDecorator, getFieldsError } = this.props.form;
        const { employeeList, reportVacation } = this.props;
        const { isLoading } = this.state;

        const formItemLayout = {
            labelCol: { span: 6 },
            wrapperCol: { span: 6 }
        };

        const buttonItemLayout = {
            wrapperCol: { span: 6, offset: 6 }
        };

        return (
            <div className={styles.wrapper}>
                <AppPageHeader title="Отчет по отпускам" />
                <Form {...formItemLayout} onSubmit={this.handleSubmit}>
                    {employeeList && (
                        <Form.Item label="Id сотрудника">
                            {getFieldDecorator('employees')(
                                <Select placeholder="" allowClear>
                                    {employeeList.map((item, index) => {
                                        return (
                                            <Option value={item.username} key={index}>
                                                {item.username}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            )}
                        </Form.Item>
                    )}

                    <Form.Item {...buttonItemLayout}>
                        <Button
                            type="primary"
                            htmlType="submit"
                            disabled={hasErrors(getFieldsError())}
                            loading={isLoading}
                        >
                            Построить отчет
                        </Button>
                    </Form.Item>
                </Form>
                <Title style={{ marginLeft: '370px' }} level={3}>
                    Количество дней отпуска: {reportVacation}
                </Title>
            </div>
        );
    }
}

const VacationPageForm = Form.create<Props>({ name: 'filter' })(VacationPage);

const mapState = (state: AppState) => {
    return {
        employeeList: state.dictionaries.employeeList,
        reportVacation: state.report.reportVac
    };
};

const mapActions = (dispatch) => ({
    getReportVacationAction: (employeeId) => Promise.all([dispatch(getVacationDay(employeeId))])
});

export default connect(mapState, mapActions)((VacationPageForm as unknown) as ComponentType<any>);
