import React from 'react';
import { Moment } from 'moment';
import { WorkLog } from '../../../types/apiDomain';
import classNames from 'classnames';
import styles from '../ReportByEmployeesCalendar.module.scss';
import { Table } from 'antd';
import { ISO_DATE_FORMAT } from '../../../constants/dateFormat';

const { Column } = Table;

const getShortMonth = (date: Moment) => date.format('MMM').substr(0, 3);

const getHoursOfWorklogs = (worklogs: WorkLog[] | undefined, date: Moment) => {
    const dateString = date.format(ISO_DATE_FORMAT);
    let hours = 0;

    worklogs?.forEach((worklog) => {
        if (worklog.date === dateString) {
            hours += worklog.minutes / 60;
        }
    });

    return hours;
};

const isWeekend = (date: Moment, holidays: string[]) => {
    return holidays.includes(date.format(ISO_DATE_FORMAT));
};

const getTitle = (date: Moment) => {
    return `${date.format('DD')} ${getShortMonth(date)}\n${date.format('ddd')}`;
};

function getCalendarHoursColumn<T>(
    date: Moment,
    index: number,
    holidays: string[],
    getWorklogs: (item: T) => WorkLog[]
) {
    return (
        <Column
            title={getTitle(date)}
            align={'center'}
            key={`date${index}`}
            render={(item: T) => {
                return getHoursOfWorklogs(getWorklogs(item), date);
            }}
            className={classNames(styles.hoursColumn, { [styles.weekend]: isWeekend(date, holidays) })}
        />
    );
}

export default getCalendarHoursColumn;
