import { AppThunkAction } from '../../../hooks/reactRedux';
import request from '../../request';
import { IssueTemplateEntity, IssueTemplateEntityRaw } from '../../../types/apiDomain';
import { issueTemplatesLoaded } from '../../../reducers/issueTemplates';
import getIssueTemplateEntityFromRaw from '../../../utils/getIssueTemplateEntityFromRaw';

const getIssueTemplates = (): AppThunkAction<Promise<void>> => async (dispatch) => {
    const listRaw = await request<IssueTemplateEntityRaw[]>({
        url: '/api/issue-template'
    });

    const list: IssueTemplateEntity[] = listRaw.map(getIssueTemplateEntityFromRaw);

    dispatch(issueTemplatesLoaded({ list }));
};

export default getIssueTemplates;
