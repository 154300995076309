import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { actionCreator } from './helpers';
import { ContractedProject, ProjectsByCustomersReport, User } from '../types/apiDomain';

export interface DictionariesState {
    projectList?: ContractedProject[];
    internalProjectList?: ContractedProject[];
    projectsByCustomers?: ProjectsByCustomersReport;
    internalProjectsByCustomers?: ProjectsByCustomersReport;
    employeeList?: User[];
    sourceList?: string[];
}

const initialState: DictionariesState = {};

export const projectListLoader = actionCreator<{ list: ContractedProject[] }>('PROJECT_LIST_LOADER');
export const internalProjectListLoader = actionCreator<{ list: ContractedProject[] }>('INTERNAL_PROJECT_LIST_LOADER');
export const projectsByCustomersLoader = actionCreator<{ list: ProjectsByCustomersReport }>(
    'PROJECTS_BY_CUSTOMERS_LOADER'
);
export const internalProjectsByCustomersLoader = actionCreator<{ list: ProjectsByCustomersReport }>(
    'INTERNAL_PROJECTS_BY_CUSTOMERS_LOADER'
);
export const employeeListLoader = actionCreator<{ list: User[] }>('EMPLOYEE_LIST_LOADER');
export const sourceListLoader = actionCreator<{ list: string[] }>('SOURCE_LIST_LOADER');

export default reducerWithInitialState(initialState)
    .case(projectListLoader, (state, { list }) => ({
        ...state,
        projectList: list
    }))
    .case(projectsByCustomersLoader, (state, { list }) => ({
        ...state,
        projectsByCustomers: list
    }))
    .case(internalProjectsByCustomersLoader, (state, { list }) => ({
        ...state,
        internalProjectsByCustomers: list
    }))
    .case(internalProjectListLoader, (state, { list }) => ({
        ...state,
        internalProjectList: list
    }))
    .case(employeeListLoader, (state, { list }) => ({
        ...state,
        employeeList: list
    }))
    .case(sourceListLoader, (state, { list }) => ({
        ...state,
        sourceList: list
    }));
