import React, { FunctionComponent, useMemo } from 'react';
import DataSelect, { DataSelectWrapperProps, ValueExtractor } from '../dataSelect/DataSelect';
import { useAppSelector } from '../../hooks/reactRedux';
import getIssueTemplates from '../../actions/api/issueTemplate/getIssueTemplates';
import { IssueTemplateEntity } from '../../types/apiDomain';
import getIssueTemplateName from '../../pages/issueTemplatePage/utils/getIssueTemplateName';

const valueExtractor: ValueExtractor<IssueTemplateEntity> = (item) => item.id;

interface Props extends DataSelectWrapperProps {
    disableFetching?: boolean;
}

const IssueTemplateSelect: FunctionComponent<Props> = ({ disableFetching, ...props }) => {
    const data = useAppSelector((state) => state.issueTemplates.list);
    const fetchData = useMemo(() => (disableFetching ? getIssueTemplates() : undefined), [disableFetching]);

    return (
        <DataSelect
            data={data}
            valueExtractor={valueExtractor}
            displayableValueExtractor={getIssueTemplateName}
            fetchData={fetchData}
            {...props}
        />
    );
};

export default IssueTemplateSelect;
