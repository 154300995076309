import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { API_HOST } from '../constants/env';
import redirectToLogin from './redirectToLogin';
import isAuthorizationError from '../utils/isAuthorizationError';

const axiosInstance = axios.create({
    baseURL: API_HOST,
    timeout: 500000
});

axiosInstance.interceptors.response.use(
    (response) => {
        console.log('RESPONSE', response);

        return response;
    },
    (error: AxiosError) => {
        console.log('ERROR', { error });

        if (isAuthorizationError(error)) {
            redirectToLogin();
        }

        return Promise.reject(error);
    }
);

type RequestOptions = AxiosRequestConfig & {
    url: string;
};

const request = <T extends object>({ url, method = 'get', ...rest }: RequestOptions): Promise<T> => {
    return axiosInstance({
        url,
        method,
        ...rest
    }).then((response) => response?.data);
};

export const post = <T extends object>(options: RequestOptions) => request<T>({ ...options, method: 'post' });
export const put = <T extends object>(options: RequestOptions) => request<T>({ ...options, method: 'put' });
export const del = <T extends object>(options: RequestOptions) => request<T>({ ...options, method: 'delete' });

export default request;
