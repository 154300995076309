// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { UserInfo, UserRole } from '../../types/apiDomain';
import { userLoaded } from '../../reducers/user';
import request from '../request';

const getCurrentUser = () => async (dispatch) => {
    const user = await request<UserInfo>({
        url: '/api/auth/account'
    });
    // // Данный код можно использовать для проверки работы ролей
    // const user: UserInfo = {
    //     id: 'kgolikov',
    //     roles: [
    //         UserRole.ADMIN,
    //         UserRole.USER
    //     ]
    // };

    return dispatch(userLoaded({ user }));
};

export default getCurrentUser;
