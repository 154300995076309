import React, { FunctionComponent } from 'react';
// @ts-ignore
import { Empty } from 'antd';
import styles from './NotFoundPage.module.css';

const NotFoundPage: FunctionComponent = () => (
    <div className={styles.wrapper}>
        <Empty description="Page not found" />
    </div>
);

export default NotFoundPage;
