import React, { FunctionComponent } from 'react';
import XlsReportTemplate, { RenderCells, RenderColumns, Renderers } from '../xlsReportTemplate/XlsReportTemplate';
import { UserWorkLog, UserWorkLogsReport } from '../../types/apiDomain';
import { minutesToHours } from '../../utils/timeConversion';

const renderHeadCells: RenderColumns<UserWorkLog> = {
    issueId: 'Issue',
    isInternal: 'Внутреннее',
    date: 'Дата',
    project: 'Проект',
    minutes: 'Время',
    comment: 'Комментарий'
};

const renderCells: RenderCells<UserWorkLog> = {
    issueId: (value, { issueUrl }) => <a href={issueUrl ?? undefined}>{value}</a>,
    isInternal: (value) => (value ? 'Да' : ''),
    minutes: (value) => Renderers.renderNumber(minutesToHours(value))
};

interface Props {
    workLogsReport?: UserWorkLogsReport;
}

const WorklogsXlsExport: FunctionComponent<Props> = ({ workLogsReport }) => {
    return (
        <XlsReportTemplate
            data={workLogsReport?.workLogs ?? []}
            filename="worklogs"
            renderColumns={renderHeadCells}
            renderCells={renderCells}
        />
    );
};

export default WorklogsXlsExport;
