import { AppThunkAction } from '../../../hooks/reactRedux';
import { put } from '../../request';
import { IssueTemplateEntity, IssueTemplateEdit, IssueTemplateEntityRaw } from '../../../types/apiDomain';
import { issueTemplateEdited } from '../../../reducers/issueTemplates';
import getIssueTemplateEntityFromRaw from '../../../utils/getIssueTemplateEntityFromRaw';

const editIssueTemplate = (
    templateId: IssueTemplateEntity['id'],
    templateEdit: IssueTemplateEdit
): AppThunkAction<Promise<IssueTemplateEntity>> => async (dispatch) => {
    const editedTemplateRaw = await put<IssueTemplateEntityRaw>({
        url: `/api/issue-template/${templateId}`,
        data: templateEdit
    });

    const editedTemplate = getIssueTemplateEntityFromRaw(editedTemplateRaw);

    dispatch(issueTemplateEdited({ templateId, editedTemplate }));

    return editedTemplate;
};

export default editIssueTemplate;
