import { userWorkLogsLoaded } from '../../../reducers/reports';
import requestGetWorkLogs, { RequestWorkLogsParams } from '../requests/requestGetWorkLogsReport';
import { AppThunkAction } from '../../../hooks/reactRedux';
import { projectsByCustomersLoader } from '../../../reducers/dictionaries';

const getUserWorkLogs = (params: RequestWorkLogsParams): AppThunkAction<Promise<void>> => async (dispatch) => {
    const workLogsReport = await requestGetWorkLogs(params);
    const { projectsByCustomers } = workLogsReport;

    dispatch(userWorkLogsLoaded({ workLogsReport }));

    if (projectsByCustomers) {
        dispatch(projectsByCustomersLoader({ list: projectsByCustomers }));
    }
};

export default getUserWorkLogs;
