import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Collapse, Descriptions, Dropdown, Menu, Spin, Table, Tooltip } from 'antd';
import styles from './MyActivitiesPage.module.css';
import ReportByEmployeesFilter from '../../forms/reportByEmployeesFilter/ReportByEmployeesFilter';
import { HoursReport, UserWorkLog } from '../../types/apiDomain';
import { ColumnProps } from 'antd/lib/table';
import getUserWorkLogs from '../../actions/api/worklogs/getUserWorkLogs';
import AppPageHeader from '../../components/appPageHeader/AppPageHeader';
import EditMode from './types/EditMode';
import ActivityEditForm from './components/activityEditForm/ActivityEditForm';
import EditFormState, { createEditingWorkLog } from './types/EditFormState';
import moment from 'moment';
import { RequestWorkLogsParams } from '../../actions/api/requests/requestGetWorkLogsReport';
import { minutesToHours } from '../../utils/timeConversion';
import classNames from 'classnames';
import getProjects from '../../actions/api/lookup/getProjects';
import { useAppDispatch, useAppSelector } from '../../hooks/reactRedux';
import { ReportParams } from '../../actions/api/requests/requestReport';
import getErrorMessage from '../../utils/getErrorMessage';
import WorklogsXlsExport from '../../components/worklogsXlsReport/WorklogsXlsExport';
import ErrorResult from '../../components/errorResult/ErrorResult';
import Text from 'antd/lib/typography/Text';
import confirmWorkLogDelete from './utils/confirmWorkLogDelete';
import deleteUserWorkLog from '../../actions/api/worklogs/deleteUserWorkLog';
import displayErrorNotification from '../../utils/ui/displayErrorNotification';
import deleteUserWorkLogByQuery from '../../actions/api/worklogs/deleteUserWorkLogByQuery';
import { EmployeeCalendarProject } from '../../types/reportTypes';
import ReportByEmployeesCalendar from '../../components/reportByEmployeesCalendar/ReportByEmployeesCalendar';

const createTableColumns = (
    handleEdit: (record: UserWorkLog) => void,
    editMode: EditMode,
    handleDelete: (record: UserWorkLog) => void
): ColumnProps<UserWorkLog>[] => {
    const isEditMode = editMode === EditMode.EDIT;

    return [
        {
            title: 'Issue ID',
            dataIndex: 'displayableIssueId',
            key: 'displayableIssueId',
            render: (text, record) => {
                const { displayableIssueId, issueUrl, isFromCurrentImportRange, isEditable } = record;

                const baseElement = isEditable ? (
                    <Button
                        type="link"
                        className={classNames(styles.linkButton, { [styles.cursorDefault]: isEditMode })}
                        onClick={() => handleEdit(record)}
                    >
                        {displayableIssueId}
                    </Button>
                ) : (
                    <a href={issueUrl ?? undefined} target="_blank" rel="noopener noreferrer">
                        {displayableIssueId}
                    </a>
                );

                const canBeDeleted = isFromCurrentImportRange; // || isInternal;

                if (canBeDeleted) {
                    const menu = (
                        <Menu>
                            {canBeDeleted && (
                                <Menu.Item onClick={() => handleDelete(record)}>
                                    <Text type="danger">Удалить</Text>
                                </Menu.Item>
                            )}
                        </Menu>
                    );

                    return <Dropdown overlay={menu}>{baseElement}</Dropdown>;
                }

                return baseElement;
            },
            className: styles.textNoWrap,
            sorter: (a, b) => a.displayableIssueId.localeCompare(b.displayableIssueId)
        },
        {
            title: 'Дата',
            dataIndex: 'date',
            key: 'date',
            className: styles.textNoWrap,
            sorter: (a, b) => moment(a.date).diff(b.date)
        },
        {
            title: 'Проект',
            dataIndex: 'project',
            key: 'project',
            sorter: (a, b) => a.project.localeCompare(b.project)
        },
        {
            title: 'Время',
            dataIndex: 'amount',
            key: 'amount',
            render: (text, { minutes }) => minutesToHours(minutes),
            sorter: (a, b) => a.minutes - b.minutes
        },
        {
            title: 'Комментарий',
            dataIndex: 'comment',
            key: 'comment',
            className: styles.textPreWrap
        }
    ];
};

const ApproximateHours: FunctionComponent<{ hoursReport?: HoursReport }> = ({ hoursReport }) => {
    if (!hoursReport?.approximateHoursForPeriod) {
        return <>Н/Д</>;
    }

    const difference = hoursReport.totalHoursForPeriod - hoursReport.approximateHoursForPeriod;
    const differenceString = `${difference > 0 ? '+' : ''}${difference}`;

    const tooltip =
        difference === 0
            ? 'Всё списано'
            : difference < 0
            ? `Осталось списать ${-difference} ч`
            : `Списано больше на ${difference} ч`;

    return (
        <div className={styles.approximateHoursContainer}>
            <Text>{hoursReport.approximateHoursForPeriod}</Text>
            <Tooltip title={tooltip} placement="bottom">
                <Text type="secondary">({differenceString})</Text>
            </Tooltip>
        </div>
    );
};

const getProjectsFromUserWorkLogs = (workLogs: UserWorkLog[] | undefined) => {
    const projects: EmployeeCalendarProject[] = [];

    workLogs?.forEach((workLog) => {
        const { project, issueId, issueUrl, issueName } = workLog;

        let foundProject = projects.find((projectObj) => projectObj.id === project);

        if (!foundProject) {
            projects.push({
                id: project,
                issues: [],
                amount: 0
            });
            foundProject = projects.find((projectObj) => projectObj.id === project);
        }

        if (foundProject) {
            const foundIssue = foundProject.issues.find((issue) => issue.id === issueId);

            const plusAmount = workLog.minutes / 60;
            foundProject.amount += plusAmount;

            if (foundIssue) {
                foundIssue.worklogs.push(workLog);

                foundIssue.amount += plusAmount;
            } else {
                foundProject.issues.push({
                    id: issueId,
                    worklogs: [workLog],
                    url: issueUrl ?? null,
                    desc: issueName ?? issueId,
                    amount: plusAmount
                });
            }
        }
    });

    return projects;
};
const getWorktimeAmountFromUserWorkLogs = (workLogs: UserWorkLog[] | undefined) => {
    let amount = 0;

    workLogs?.forEach((workLog) => {
        amount += workLog.minutes / 60;
    });

    return amount;
};

const MyActivitiesPage: FunctionComponent = () => {
    const dispatch = useAppDispatch();
    const workLogsReport = useAppSelector((state) => state.report.userWorkLogsReport);
    const user = useAppSelector((state) => state.user.user);
    const employeeList = useAppSelector((state) => state.dictionaries.employeeList) ?? [];
    globalThis.employeeList = useAppSelector((state) => state.dictionaries.employeeList) ?? [];

    const { workLogs, hoursReport, holidays } = useMemo(
        () => ({
            workLogs: workLogsReport?.workLogs,
            hoursReport: workLogsReport?.hoursReport,
            holidays: workLogsReport?.holidays
        }),
        [workLogsReport]
    );
    const { userObj } = useMemo(
        () => ({
            userObj: employeeList.find((employee) => {
                return employee.username === user?.id;
            })
        }),
        [employeeList, user]
    );

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<string>();
    const [editFormState, setEditFormState] = useState<EditFormState>({
        mode: EditMode.NONE
    });

    const [adminUser, setAdminUser] = useState<string>();

    const [filters, setFilters] = useState<RequestWorkLogsParams>();

    useEffect(() => {
        getProjects(true);
    }, []);

    const createReport = useCallback(
        ({ employees, ...filterParams }: ReportParams) => {
            const params: RequestWorkLogsParams = {
                ...filterParams,
                user: employees
            };

            setFilters(params);

            setIsLoading(true);
            setError(undefined);
            dispatch(getUserWorkLogs(params))
                .catch((error) => setError(getErrorMessage(error, 'При получении данных произошла ошибка')))
                .finally(() => setIsLoading(false));
        },
        [dispatch]
    );

    const handleEdit = useCallback(
        (record: UserWorkLog) => {
            const selection = window.getSelection();
            if (selection?.type === 'Range' || editFormState.mode === EditMode.EDIT) {
                return;
            }

            setEditFormState((state) => ({
                ...state,
                mode: EditMode.EDIT,
                editingItem: createEditingWorkLog(record)
            }));
        },
        [editFormState.mode]
    );

    const handleDelete = useCallback(
        (workLog: UserWorkLog) => {
            confirmWorkLogDelete(async () => {
                try {
                    if (workLog.isInternal) {
                        await dispatch(deleteUserWorkLog(workLog.issueId, adminUser));
                    } else {
                        await dispatch(deleteUserWorkLogByQuery(workLog, adminUser));
                    }
                } catch (error) {
                    displayErrorNotification(error);
                }
            });
        },
        [adminUser, dispatch]
    );

    const tableColumns = useMemo(() => createTableColumns(handleEdit, editFormState.mode, handleDelete), [
        editFormState.mode,
        handleDelete,
        handleEdit
    ]);

    const handleEmployeeChange = useCallback((value: string[] | string | undefined) => {
        if (Array.isArray(value)) {
            return;
        }

        setAdminUser(value);
    }, []);

    return (
        <div className={styles.container}>
            <AppPageHeader title="Списания" />

            <ReportByEmployeesFilter
                submitFilter={createReport}
                adminSingleEmployeeFilter
                onEmployeesChange={handleEmployeeChange}
                exportNode={<WorklogsXlsExport workLogsReport={workLogsReport} />}
            />
            {error ? (
                <ErrorResult error={error} />
            ) : (
                <div>
                    <ActivityEditForm
                        editFormState={editFormState}
                        setEditFormState={setEditFormState}
                        adminUser={adminUser}
                    />

                    <Spin spinning={isLoading}>
                        <Collapse defaultActiveKey={['1']} className={styles.reportInfoCollapse}>
                            <Collapse.Panel header="Информация об отчёте" key="1">
                                <Descriptions title="Отчёт по часам" column={4}>
                                    {hoursReport ? (
                                        <>
                                            <Descriptions.Item label="Внутренних">
                                                {hoursReport.totalHoursForPeriodInternal}
                                            </Descriptions.Item>
                                            <Descriptions.Item label="Внешних">
                                                {hoursReport.totalHoursForPeriodExternal}
                                            </Descriptions.Item>
                                            <Descriptions.Item label="Всего">
                                                {hoursReport.totalHoursForPeriod}
                                            </Descriptions.Item>
                                            <Descriptions.Item label="Ориентировочно">
                                                <ApproximateHours hoursReport={hoursReport} />
                                            </Descriptions.Item>
                                        </>
                                    ) : (
                                        <Descriptions.Item>Нет данных</Descriptions.Item>
                                    )}
                                </Descriptions>
                            </Collapse.Panel>
                        </Collapse>

                        <ReportByEmployeesCalendar
                            className={styles.calendar}
                            tableData={{
                                startDate: filters?.startDate,
                                endDate: filters?.endDate,
                                holidays: holidays ?? [],
                                employees: [
                                    {
                                        name: user?.id ?? '',
                                        firstName: userObj?.firstName ?? '',
                                        lastName: userObj?.lastName ?? '',
                                        amount: getWorktimeAmountFromUserWorkLogs(workLogs),
                                        projects: getProjectsFromUserWorkLogs(workLogs)
                                    }
                                ]
                            }}
                        />

                        <Table
                            rowKey={(record, index) => index.toString()}
                            columns={tableColumns}
                            dataSource={workLogs}
                        />
                    </Spin>
                </div>
            )}
        </div>
    );
};

export default MyActivitiesPage;
