import request from '../../request';
import { ProjectsByCustomersReport } from '../../../types/apiDomain';
import { internalProjectsByCustomersLoader, projectsByCustomersLoader } from '../../../reducers/dictionaries';
import { AppThunkAction } from '../../../hooks/reactRedux';

export default (isInternal?: boolean): AppThunkAction<Promise<void>> => (dispatch) =>
    request<ProjectsByCustomersReport>({
        url: `/api/lookup/projects-by-customers${isInternal ? '/internal' : ''}`
    }).then((list) => {
        if (isInternal) {
            dispatch(internalProjectsByCustomersLoader({ list }));
        } else {
            dispatch(projectsByCustomersLoader({ list }));
        }
    });
