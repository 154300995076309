import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { AppState } from '../reducers/rootReducer';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';

export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector;
export const useAppDispatch = () => useDispatch<ThunkDispatch<AppState, void, AnyAction>>();

export type AppDispatch = ReturnType<typeof useAppDispatch>;
export type AppThunkAction<T = void> = (dispatch: AppDispatch) => T;
