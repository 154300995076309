import { reportByProjectsLoaded } from '../../../reducers/reports';
import { projectsByCustomersLoader } from '../../../reducers/dictionaries';
import requestReport, { ReportParams } from '../requests/requestReport';
import { AppDispatch } from '../../../hooks/reactRedux';
import { ProjectsReport } from '../../../types/apiDomain';

const getReportByProjects = (params: ReportParams) => async (dispatch: AppDispatch) => {
    const reportByEmployees = await requestReport<ProjectsReport>(params, '/api/report/projects');
    const projectsByCustomers = reportByEmployees.projectsByCustomers;

    dispatch(projectsByCustomersLoader({ list: projectsByCustomers }));
    dispatch(reportByProjectsLoaded({ report: reportByEmployees }));
};

export default getReportByProjects;
