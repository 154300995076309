import request from '../request';
import { reportByProjectLoaded } from '../../reducers/reports';
import { ReportByProject } from '../../types/apiDomain';

export type ReportByProjectParams = {
    projectId: string;
    reportDate: string;
    startDate: string;
    endDate: string;
    docStartDate?: string;
    docEndDate?: string;
    fakeHours?: string;
};

export default ({
    projectId,
    reportDate,
    startDate,
    endDate,
    docEndDate,
    docStartDate,
    fakeHours
}: ReportByProjectParams) => (dispatch) =>
    request<ReportByProject>({
        url: `/api/report/project/create`,
        params: {
            projectId,
            reportDate,
            startDate,
            endDate,
            docEndDate,
            docStartDate,
            fakeHours
        }
    }).then((ReportByProject) => dispatch(reportByProjectLoaded({ report: ReportByProject })));
