import { IssueCreationResult, PasswordResetRequest } from '../../../types/apiDomain';
import { post } from '../../request';

const requestPasswordReset = (data: PasswordResetRequest): Promise<IssueCreationResult> => {
    return post<IssueCreationResult>({
        url: '/api/jira/reset-password',
        data
    });
};

export default requestPasswordReset;
