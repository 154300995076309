import { notification } from 'antd';
import { mapValues, omit, pick } from 'lodash';
import { ArgsProps, NotificationApi } from 'antd/lib/notification';

const defaults: Partial<ArgsProps> = {
    placement: 'bottomLeft',
    className: 'app-notification'
};

const appNotification: NotificationApi = (() => {
    const original = notification;

    const changedFields = ['error', 'success', 'open', 'info', 'warn', 'warning'] as const;

    return {
        ...omit(original, changedFields),
        ...mapValues(pick(original, changedFields), (func) => (args: ArgsProps) =>
            func({
                ...defaults,
                ...args
            })
        )
    };
})();

export default appNotification;
