import { UserWorkLog, UserWorkLogEdit } from '../../../../../types/apiDomain';
import moment, { Moment } from 'moment';
import { hoursToMinutes, minutesToHours } from '../../../../../utils/timeConversion';
import { ISO_DATE_FORMAT } from '../../../../../constants/dateFormat';

interface ActivityEditFormObject extends Omit<UserWorkLogEdit, 'minutes' | 'date' | 'project' | 'externalId'> {
    issueId?: string;
    amount?: string;
    date: Moment;
    project?: string;
    externalId?: string;
}

export const createActivityEditFormObject = (workLog: Partial<UserWorkLog>): ActivityEditFormObject => ({
    issueId: workLog.issueId,
    amount: workLog.minutes ? minutesToHours(workLog.minutes).toString() : undefined,
    comment: workLog.comment ?? '',
    date: moment(workLog.date, ISO_DATE_FORMAT),
    project: workLog.project,
    externalId: workLog.externalId ?? ''
});

export const createUserWorkLogEditFromFormObject = (formObject: ActivityEditFormObject): UserWorkLogEdit => {
    if (!formObject.project || formObject.amount === undefined) {
        console.error(formObject);
        throw new Error('createUserWorkLogEditFromFormObject: something is undefined');
    }

    return {
        project: formObject.project,
        date: formObject.date.format(ISO_DATE_FORMAT),
        minutes: hoursToMinutes(Number(formObject.amount)),
        comment: formObject.comment,
        externalId: formObject.externalId ?? ''
    };
};

export default ActivityEditFormObject;
