import { compareFields, compareNumbers, compareStrings } from './sorting';
import { EmployeeStats, IssueStats } from '../types/apiDomain';
import tinycolor2 from 'tinycolor2';
import { EmployeeCalendarIssue } from '../types/reportTypes';

const START_DATE = '1900-01-01';

const getMinObjectFieldValue = <T>(array: T[], field: string): T | undefined => {
    if (!array) {
        return;
    }

    let minValue = array[0];
    for (const value of array) {
        if (value[field] < minValue[field]) {
            minValue = value;
        }
    }
    return minValue;
};

const getFirstIssueDate = (issue: IssueStats | EmployeeCalendarIssue) => {
    const worklog = getMinObjectFieldValue(issue.worklogs, 'date');
    return worklog ? worklog.date : START_DATE;
};

export const idSort = compareFields<string, { id: string }>('id', compareStrings);
export const nameSort = compareFields<string, { name: string }>('name', compareStrings);
export const lastNameSort = compareFields<string, { lastName: string }>('lastName', compareStrings);
export const amountSort = compareFields<number, { amount: number }>('amount', compareNumbers);
export const qualitySort = compareFields<number, { qualityIndex: number }>('qualityIndex', compareNumbers);
export const colorSort = (a: EmployeeStats, b: EmployeeStats) =>
    tinycolor2(a.status).toHsv().h - tinycolor2(b.status).toHsv().h;
export const dateSort = compareFields<string, { date: string }>('date', compareStrings);
export const worklogDateSort = (a: IssueStats | EmployeeCalendarIssue, b: IssueStats | EmployeeCalendarIssue) =>
    getFirstIssueDate(a).localeCompare(getFirstIssueDate(b));
export const descSort = compareFields<string, { desc: string }>('desc', compareStrings);
