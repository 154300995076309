import { AppThunkAction } from '../../../hooks/reactRedux';
import { IssueTemplateEntity } from '../../../types/apiDomain';
import { del } from '../../request';
import { issueTemplateDeleted } from '../../../reducers/issueTemplates';

const deleteIssueTemplate = (templateId: IssueTemplateEntity['id']): AppThunkAction<Promise<void>> => async (
    dispatch
) => {
    await del({
        url: `/api/issue-template/${templateId}`
    });

    dispatch(issueTemplateDeleted({ templateId }));
};

export default deleteIssueTemplate;
