import request from '../request';

export type ExportsParams = {
    date: string;
};

export default (date: ExportsParams) =>
    request({
        url: `/api/export/${date}`
    });
