import { UserInfo } from '../types/apiDomain';
import { actionCreator } from './helpers';
import { reducerWithInitialState } from 'typescript-fsa-reducers';

export interface UserState {
    user?: UserInfo;
}

const initialState: UserState = {};

export const userLoaded = actionCreator<{ user: UserInfo }>('USER_LOADED');

export default reducerWithInitialState(initialState).case(userLoaded, (state, { user }) => ({
    ...state,
    user
}));
