import React, { FunctionComponent } from 'react';
import { Button } from 'antd';
import styles from './EditingMenu.module.css';
import EditMode from '../../../../types/EditMode';

interface Props {
    editMode: EditMode;

    isSavingLoading?: boolean;
    isDeletingLoading?: boolean;

    onAddActivityClick?: React.MouseEventHandler;
    onSaveClick?: React.MouseEventHandler;
    onDeleteClick?: React.MouseEventHandler;
    onCancelClick?: React.MouseEventHandler;
}

const EditingMenu: FunctionComponent<Props> = (props) => {
    const {
        editMode,
        isSavingLoading,
        isDeletingLoading,
        onAddActivityClick,
        onSaveClick,
        onDeleteClick,
        onCancelClick
    } = props;

    return (
        <div className={styles.container}>
            <div className={styles.leftContainer}>
                {editMode === EditMode.NONE ? (
                    <Button key="add-button" type="primary" htmlType="button" onClick={onAddActivityClick}>
                        Добавить списание
                    </Button>
                ) : (
                    <Button
                        key="save-button"
                        type="primary"
                        htmlType="submit"
                        onClick={onSaveClick}
                        loading={isSavingLoading}
                    >
                        Сохранить
                    </Button>
                )}

                {editMode === EditMode.EDIT && (
                    <Button type="danger" htmlType="button" onClick={onDeleteClick} loading={isDeletingLoading}>
                        Удалить
                    </Button>
                )}
            </div>

            {editMode !== EditMode.NONE && (
                <Button type="default" htmlType="button" onClick={onCancelClick}>
                    Отмена
                </Button>
            )}
        </div>
    );
};

export default EditingMenu;
