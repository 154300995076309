import request from '../request';
import { ReportByEmployees } from '../../types/apiDomain';

export type ImportTasksParams = {
    source: string;
};

export default ({ source }: ImportTasksParams) =>
    request<ReportByEmployees>({
        url: `/api/import`,
        params: {
            source
        }
    });
