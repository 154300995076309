import React, { FormEvent, FunctionComponent } from 'react';
import { Button, DatePicker, Form, Input, InputNumber, Select } from 'antd';
import styles from '../../../../styles/filterForm.module.scss';
import { ANTD_COLS } from '../../../../constants/markup';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import TextArea from 'antd/lib/input/TextArea';
import ExportLogType from './types/ExportLogType';
import ExportLogFilter from './types/ExportLogFilter';
import BoolEnum from '../../../../types/BoolEnum';

const { RangePicker } = DatePicker;

const labelCol = 6;

interface Props {
    onFilter: (filter: ExportLogFilter) => void;
}

interface InternalProps extends Props {
    form: WrappedFormUtils;
}

const ExportLogsFilter: FunctionComponent<InternalProps> = ({ form, onFilter }) => {
    const handleFormSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const filter = form.getFieldsValue() as ExportLogFilter;

        onFilter(filter);
    };

    const handleFormReset = () => {
        form.resetFields();
        onFilter({});
    };

    return (
        <Form
            className={styles.form}
            labelCol={{ span: labelCol }}
            wrapperCol={{ span: ANTD_COLS - labelCol }}
            onSubmit={handleFormSubmit}
        >
            <Form.Item label="UUID">{form.getFieldDecorator('uuid')(<Input allowClear />)}</Form.Item>
            <Form.Item label="Дата">
                {form.getFieldDecorator('date')(<RangePicker className="date-picker-w100" allowClear />)}
            </Form.Item>
            <Form.Item label="Callback URL">{form.getFieldDecorator('callbackUrl')(<Input allowClear />)}</Form.Item>
            <Form.Item label="Код ответа">
                {form.getFieldDecorator('responseCode')(<InputNumber className="w-100" min={0} max={599} />)}
            </Form.Item>
            <Form.Item label="Тело ответа">{form.getFieldDecorator('response')(<TextArea rows={1} />)}</Form.Item>
            <Form.Item label="Тип">
                {form.getFieldDecorator('type')(
                    <Select allowClear>
                        <Select.Option value={ExportLogType.UNKNOWN}>Неизвестно</Select.Option>
                        <Select.Option value={ExportLogType.SUCCESS}>Успешные</Select.Option>
                        <Select.Option value={ExportLogType.ERROR}>Ошибки</Select.Option>
                    </Select>
                )}
            </Form.Item>
            <Form.Item label="Данные">
                {form.getFieldDecorator('hasData')(
                    <Select allowClear>
                        <Select.Option value={BoolEnum.TRUE}>Есть</Select.Option>
                        <Select.Option value={BoolEnum.FALSE}>Нет</Select.Option>
                    </Select>
                )}
            </Form.Item>
            <Form.Item label="Ответ">
                {form.getFieldDecorator('hasResponse')(
                    <Select allowClear>
                        <Select.Option value={BoolEnum.TRUE}>Есть</Select.Option>
                        <Select.Option value={BoolEnum.FALSE}>Нет</Select.Option>
                    </Select>
                )}
            </Form.Item>
            <Form.Item wrapperCol={{ offset: labelCol, span: ANTD_COLS - labelCol }}>
                <div className={styles.formButtonsContainer}>
                    <Button type="primary" htmlType="submit">
                        Применить
                    </Button>
                    <Button type="default" onClick={handleFormReset}>
                        Сброс
                    </Button>
                </div>
            </Form.Item>
        </Form>
    );
};

export default (Form.create()(ExportLogsFilter) as unknown) as FunctionComponent<Props>;
