import React, { PureComponent } from 'react';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { EmployeeReport, ReportByEmployees, TableRow } from '../../types/apiDomain';
import moment from 'moment';
import { minutesToHours } from '../../utils/timeConversion';
import { ISO_DATE_FORMAT } from '../../constants/dateFormat';

interface Props {
    tableData: ReportByEmployees | EmployeeReport;
    showRateField?: boolean;
}

const startOfMonth = moment()
    .startOf('month')
    .format(ISO_DATE_FORMAT);
const endOfMonth = moment()
    .endOf('month')
    .format(ISO_DATE_FORMAT);

function getRowsData(data) {
    const result = [];

    data?.employees &&
        data.employees.forEach((employee) => {
            employee.projects.forEach((project) => {
                project.issues.forEach((issue) => {
                    issue.worklogs.forEach((workLog) => {
                        const itemRow = {
                            name: employee.name.toString(),
                            firstName: employee.firstName.toString(),
                            lastName: employee.lastName.toString(),
                            amount: employee.amount.toString().replace('.', ','),
                            projectId: project.id.toString(),
                            issueId: issue.id.toString(),
                            issueDesc: issue.desc?.toString(),
                            issueUrl: issue.url?.toString(),
                            issueAmount: issue.amount?.toString().replace('.', ','),
                            issueMarkers: issue.qualityMarkers?.join(', '),
                            workLogHours: minutesToHours(workLog.minutes)
                                .toFixed(1)
                                .replace('.', ','),
                            workLogDate: workLog.date.toString(),
                            workLogComment: workLog.comment.toString()
                        };

                        // @ts-ignore
                        result.push(itemRow);
                    });
                });
            });
        });

    return result;
}

export default class EmployeeXlsExport extends PureComponent<Props> {
    render() {
        const { tableData } = this.props;

        return (
            <span style={{ marginLeft: '12px' }}>
                <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="download-table-xls-button ant-btn"
                    table="table-to-xls"
                    filename={`Summary_${startOfMonth}-${endOfMonth}`}
                    sheet="Отчет"
                    buttonText="Скачать как XLS"
                />
                <table id="table-to-xls" style={{ display: 'none' }}>
                    <thead>
                        <tr>
                            <th>
                                <b>name</b>
                            </th>
                            <th>
                                <b>firstName</b>
                            </th>
                            <th>
                                <b>lastName</b>
                            </th>
                            <th>
                                <b>amount</b>
                            </th>
                            <th>
                                <b>projectId</b>
                            </th>
                            <th>
                                <b>issueId</b>
                            </th>
                            <th>
                                <b>issueDesc</b>
                            </th>
                            <th>
                                <b>issueAmount</b>
                            </th>
                            <th>
                                <b>issueQualityMarkers</b>
                            </th>
                            <th>
                                <b>workLogHours</b>
                            </th>
                            <th>
                                <b>workLogDate</b>
                            </th>
                            <th>
                                <b>workLogComment</b>
                            </th>
                        </tr>
                    </thead>

                    <tbody>
                        {getRowsData(tableData).map((row: TableRow, index) => {
                            return (
                                <tr key={index}>
                                    <td>{row.name}</td>
                                    <td>{row.firstName}</td>
                                    <td>{row.lastName}</td>
                                    <td>{row.amount}</td>
                                    <td>{row.projectId}</td>
                                    <td>{row.issueId}</td>
                                    <td>
                                        <a href={row.issueUrl}>{row.issueDesc}</a>
                                    </td>
                                    <td>{row.issueAmount.toString()}</td>
                                    <td>{row.issueMarkers}</td>
                                    <td>{row.workLogHours}</td>
                                    <td>{row.workLogDate}</td>
                                    <td>{row.workLogComment}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </span>
        );
    }
}
