import React, { ComponentType, FunctionComponent, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Table } from 'antd';
import { EmployeeStats, User, WorkLog } from '../../types/apiDomain';
import { AppState } from '../../reducers/rootReducer';
import moment, { Moment } from 'moment';
import { ISO_DATE_FORMAT, RU_DATE_FORMAT } from '../../constants/dateFormat';
import { getRowKey } from '../../utils/reportUtils';
import { amountSort, dateSort, lastNameSort, worklogDateSort } from '../../utils/reportSorting';
import { useCustomExpandColumn } from '../../hooks/useCustomExpandColumn';
import getCalendarHoursColumn from './utils/getCalendarHoursColumn';
import styles from './ReportByEmployeesCalendar.module.scss';
import classNames from 'classnames';
import ReportByEmployeesCalendarIssues from './components/ReportByEmployeesCalendarIssues';
import {
    EmployeeCalendarData,
    EmployeeCalendarIssue,
    EmployeeCalendarStats,
    EmployeeCalendarWorklog
} from '../../types/reportTypes';

const { Column } = Table;

interface Props {
    tableData: EmployeeCalendarData;
    projectList: string[];
    employeeList: User[];
    className: string;
}

const getAllWorkLogsOfEmployee = (employee: EmployeeCalendarStats) => {
    const worklogs: EmployeeCalendarWorklog[] = [];
    employee?.projects.forEach((project) => {
        project?.issues.forEach((issue) => {
            issue?.worklogs.forEach((worklog) => {
                worklogs.push(worklog);
            });
        });
    });
    return worklogs.sort(dateSort);
};

const getAllIssuesOfEmployee = (employee: EmployeeCalendarStats) => {
    const issues: EmployeeCalendarIssue[] = [];
    employee?.projects.forEach((project) => {
        project?.issues.forEach((issue) => {
            issues.push(issue);
        });
    });
    return issues.sort(worklogDateSort);
};

const getDates = (startDate: string | undefined, endDate: string | undefined) => {
    const dateArray: Moment[] = [];
    if (startDate && endDate) {
        let dateFormat = RU_DATE_FORMAT;

        let currentDate = moment(startDate, dateFormat);
        if (!currentDate.isValid()) {
            dateFormat = ISO_DATE_FORMAT;
            currentDate = moment(startDate, dateFormat);
        }

        const stopDate = moment(endDate, dateFormat);

        while (currentDate <= stopDate) {
            dateArray.push(currentDate);
            currentDate = currentDate.clone().add(1, 'd');
        }
    }
    return dateArray;
};

const ReportByEmployeesCalendar: FunctionComponent<Props> = ({ tableData, className }) => {
    const [expandedKeys, setExpandedKeys] = useState<string[]>([]);

    const allAvailableKeys = useMemo<string[]>(() => tableData.employees.map(getRowKey) ?? [], [tableData]);

    const worklogsByEmployees = useMemo<Record<string, WorkLog[]>>(() => {
        const result: Record<string, WorkLog[]> = {};
        tableData.employees.forEach((employee) => {
            result[employee.name] = getAllWorkLogsOfEmployee(employee);
        });
        return result;
    }, [tableData]);

    const dates = useMemo<Moment[]>(() => getDates(tableData.startDate, tableData.endDate), [tableData]);

    const sortedEmployers = useMemo<EmployeeCalendarStats[]>(() => {
        if (!tableData.employees) {
            return [];
        }

        return [...tableData.employees].sort(lastNameSort);
    }, [tableData.employees]);

    const expandColumn = useCustomExpandColumn(sortedEmployers, allAvailableKeys, expandedKeys, setExpandedKeys);

    return (
        <Table
            className={classNames('components-table-demo-nested', styles.table, className)}
            dataSource={sortedEmployers}
            rowKey={getRowKey}
            pagination={false}
            size={'middle'}
            expandedRowRender={(employee) => (
                <ReportByEmployeesCalendarIssues
                    issues={getAllIssuesOfEmployee(employee)}
                    dates={dates}
                    holidays={tableData.holidays}
                />
            )}
            expandRowByClick
            expandedRowKeys={expandedKeys}
            expandIconAsCell={false}
            expandIconColumnIndex={-1}
        >
            {expandColumn}
            <Column
                title={'Фамилия Имя'}
                dataIndex={'lastName'}
                key={'lastName'}
                className={styles.nameColumn}
                render={(lastName, employee: EmployeeStats) => (
                    <div>
                        {employee.lastName} {employee.firstName}
                    </div>
                )}
            />

            {dates.map((date, index) => {
                return getCalendarHoursColumn<EmployeeStats>(
                    date,
                    index,
                    tableData.holidays,
                    ({ name }) => worklogsByEmployees[name]
                );
            })}

            <Column
                title={'Итого'}
                dataIndex={'amount'}
                key={'amount'}
                align={'center'}
                sorter={amountSort}
                className={styles.totalColumn}
            />
        </Table>
    );
};

const mapState = (state: AppState) => {
    return {
        projectList: state.dictionaries.projectList,
        employeeList: state.dictionaries.employeeList
    };
};

export default (connect(mapState, null)(ReportByEmployeesCalendar) as unknown) as ComponentType<Partial<Props>>;
