import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { ExportLogs } from '../../types/apiDomain';
import { Table } from 'antd';
import Text from 'antd/lib/typography/Text';
import ResponseCode from '../../utils/ResponseCode';
import styles from './ExportLogsTable.module.scss';
import moment from 'moment';
import { DATETIME_HR_FORMAT } from '../../constants/dateFormat';
import { compareBooleans, compareDates, compareFields, compareNumbers, compareStrings } from '../../utils/sorting';
import classNames from 'classnames';
import logMatchesFilter from './components/exportLogsFilter/utils/logMatchesFilter';
import ExportLogFilter from './components/exportLogsFilter/types/ExportLogFilter';
import getExportLogType from './components/exportLogsFilter/utils/getExportLogType';
import ExportLogType from './components/exportLogsFilter/types/ExportLogType';
import ExportLogsFilter from './components/exportLogsFilter/ExportLogsFilter';
import { PaginationConfig } from 'antd/lib/pagination';

const { Column } = Table;

interface Props {
    data?: ExportLogs[];
    loading?: boolean;
}

const renderSentData = ({ callbackUrl }) => <>{callbackUrl}</>;

const renderResponseCode = (responseCode) => (
    <Text type={ResponseCode.isError(responseCode) ? 'danger' : undefined}>{responseCode}</Text>
);

const renderResponseData = (responseData) => (
    <Text className={classNames('monospace', styles.responseData)}>{responseData}</Text>
);

const renderDate = (date) => <Text className={styles.textNoWrap}>{moment(date).format(DATETIME_HR_FORMAT)}</Text>;

const dateSorter = compareFields('date', compareDates);

const getRowKey = (key?) => (entry, index: number) => {
    return `row_key_${key ? entry[key] : index}`;
};

const subPagination: PaginationConfig = {
    hideOnSinglePage: true
};

const expandedExportWorkLogItemRowRender = (entry) => {
    console.log(entry.workLogs);

    return (
        <Table dataSource={entry.workLogs} pagination={subPagination} rowKey={getRowKey()}>
            <Column
                title="Сотрудник"
                dataIndex="employee"
                key="employee"
                sorter={compareFields('employee', compareStrings)}
            />
            <Column
                title="Комментарий"
                dataIndex="comment"
                key="comment"
                sorter={compareFields('comment', compareStrings)}
            />
            <Column title="Часы" dataIndex="hours" key="hours" sorter={compareFields('hours', compareNumbers)} />
            <Column title="Дата" dataIndex="date" key="date" sorter={compareFields('date', compareDates)} />
        </Table>
    );
};

const expandedSendDataItemRowRender = (entry) => {
    return (
        <Table
            dataSource={entry.sentData.items}
            pagination={subPagination}
            rowKey={getRowKey('_id')}
            expandedRowRender={expandedExportWorkLogItemRowRender}
        >
            <Column title="ID" dataIndex="_id" key="_id" sorter={compareFields('_id', compareStrings)} />
            <Column
                title="Проект"
                dataIndex="project"
                key="project"
                sorter={compareFields('project', compareStrings)}
            />
            <Column title="Описание" dataIndex="desc" key="desc" sorter={compareFields('desc', compareStrings)} />
            <Column
                title="URL"
                dataIndex="url"
                key="url"
                sorter={compareFields('url', compareStrings)}
                render={(url) => (
                    <a href={url} target="_blank" rel="noreferrer noopener">
                        {url}
                    </a>
                )}
            />
            <Column
                title="Обновлено"
                dataIndex="updatedAt"
                key="updatedAt"
                sorter={compareFields('updatedAt', compareDates)}
                render={(date) => moment(date).format(DATETIME_HR_FORMAT)}
            />
            <Column
                title="Удалено"
                dataIndex="deleted"
                key="deleted"
                sorter={compareFields('deleted', compareBooleans)}
            />
        </Table>
    );
};

const getExportLogRowClassName = (log: ExportLogs): string => {
    return {
        [ExportLogType.SUCCESS]: styles.exportLogSuccess,
        [ExportLogType.ERROR]: styles.exportLogError,
        [ExportLogType.UNKNOWN]: styles.exportLogWarning
    }[getExportLogType(log)];
};

const ExportLogsTable: FunctionComponent<Props> = ({ data }) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [filter, setFilter] = useState<ExportLogFilter>({});

    const filteredData = useMemo<ExportLogs[] | undefined>(() => {
        if (!data?.length) {
            return data;
        }

        return data.filter((log) => logMatchesFilter(log, filter));
    }, [data, filter]);

    useEffect(() => {
        if (isLoading && data) {
            setIsLoading(false);
        }
    }, [data, isLoading]);

    return (
        <div>
            <ExportLogsFilter onFilter={setFilter} />

            <Table
                className="components-table-demo-nested"
                dataSource={filteredData}
                rowKey={getRowKey('uuid')}
                expandedRowRender={expandedSendDataItemRowRender}
                expandRowByClick
                loading={isLoading}
                rowClassName={getExportLogRowClassName}
            >
                <Column title="UUID" dataIndex="uuid" key="uuid" sorter={compareFields('uuid', compareStrings)} />
                <Column title="Дата" dataIndex="date" key="date" render={renderDate} sorter={dateSorter} />
                <Column
                    title="Кол-во"
                    key="count"
                    className={styles.textNoWrap}
                    render={(record: ExportLogs) => <>{record.sentData?.items?.length}</>}
                    sorter={compareFields('sentData.items.length', compareNumbers)}
                />
                <Column
                    title="Callback URL"
                    dataIndex="sentData"
                    key="sentData"
                    render={renderSentData}
                    sorter={compareFields('sentData.callbackUrl', compareStrings)}
                />
                <Column
                    title="Код ответа"
                    dataIndex="responseCode"
                    key="responseCode"
                    render={renderResponseCode}
                    sorter={compareFields('responseCode', compareNumbers)}
                />
                <Column
                    title="Ответ"
                    dataIndex="responseData"
                    key="responseData"
                    render={renderResponseData}
                    sorter={compareFields('responseData', compareStrings)}
                />
            </Table>
        </div>
    );
};

export default ExportLogsTable;
