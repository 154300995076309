import { ExportLogsCallback } from '../../../../types/apiDomain';
import ExportLogCallbackFilter from '../types/ExportLogCallbackFilter';
import QueryMatching from '../../../../utils/QueryMatching';
import getExportLogCallbackType from './getExportLogCallbackType';

const logCallbackMatchesFilter = (logCallback: ExportLogsCallback, filter: ExportLogCallbackFilter): boolean => {
    return QueryMatching.match({
        byUuid: QueryMatching.matchString(logCallback.uuid, filter.uuid),
        byDate: QueryMatching.matchDateRange(logCallback.date, filter.date),
        byType: QueryMatching.matchNumber(getExportLogCallbackType(logCallback), filter.type)
    });
};

export default logCallbackMatchesFilter;
