import React, { FunctionComponent } from 'react';
import DataSelect, { DataSelectWrapperProps } from '../dataSelect/DataSelect';
import { useAppSelector } from '../../hooks/reactRedux';
import getProjectsByCustomers from '../../actions/api/lookup/getProjectsByCustomers';

const fetchData = getProjectsByCustomers();

const CustomerSelect: FunctionComponent<DataSelectWrapperProps> = (props) => {
    const customers = useAppSelector((state) => state.dictionaries.projectsByCustomers?.customers);

    return <DataSelect data={customers} fetchData={fetchData} {...props} />;
};

export default CustomerSelect;
