import React, { ReactElement, useCallback, useMemo, useRef } from 'react';
import { Table } from 'antd';
import { getRowKey, getToggledKeys } from '../utils/reportUtils';

const { Column } = Table;

const COLLAPSED_TEXT = '-';
const EXPANDED_TEXT = '+';

export function useCustomExpandColumn<T>(
    records: T[],
    allAvailableKeys: string[],
    expandedKeys: string[],
    onExpandedKeysChange: (newExpandedKeys: string[]) => void
): ReactElement {
    const isOpen = useMemo<boolean>(() => {
        return allAvailableKeys.every((e) => expandedKeys.includes(e));
    }, [allAvailableKeys, expandedKeys]);

    const expandableRecords = useMemo<Map<T, boolean>>(() => {
        return records.reduce((result, record) => {
            return result.set(record, expandedKeys.includes(getRowKey(record)));
        }, new Map<T, boolean>());
    }, [expandedKeys, records]);

    const clickTargetRef = useRef<HTMLDivElement>();

    const handleExpandAllClick = useCallback(() => {
        onExpandedKeysChange(isOpen ? [] : allAvailableKeys);

        const currentTarget = clickTargetRef.current;
        if (!currentTarget) {
            return;
        }
        const table = currentTarget.closest('table');
        const expandButtons =
            table?.querySelectorAll<HTMLElement>('tbody th .ant-table-column-title .ant-table-row-expand-icon') ?? [];
        for (const expandButton of expandButtons) {
            expandButton.click();
        }
    }, [allAvailableKeys, isOpen, onExpandedKeysChange]);

    const handleExpandRowClick = useCallback(
        (record: T) => () => {
            onExpandedKeysChange(getToggledKeys(getRowKey(record), expandedKeys));
        },
        [expandedKeys, onExpandedKeysChange]
    );

    return useMemo(
        () => (
            <Column
                className={'ant-table-row-expand-icon-cell'}
                key={'expand'}
                align={'center'}
                title={() => {
                    return (
                        <div className={'ant-table-row-expand-icon'} onClick={handleExpandAllClick}>
                            {isOpen ? COLLAPSED_TEXT : EXPANDED_TEXT}
                        </div>
                    );
                }}
                render={(record: T) => (
                    <div className={'ant-table-row-expand-icon'} onClick={handleExpandRowClick(record)}>
                        {expandableRecords.get(record) ? COLLAPSED_TEXT : EXPANDED_TEXT}
                    </div>
                )}
            />
        ),
        [expandableRecords, handleExpandRowClick, handleExpandAllClick, isOpen]
    );
}
