import { userWorkLogDeleted } from '../../../reducers/reports';
import { UserWorkLog } from '../../../types/apiDomain';
import { del } from '../../request';

const deleteUserWorkLog = (issueId: string, user?: string) => async (dispatch) => {
    await del<UserWorkLog>({
        url: `/api/report/worklogs/${encodeURIComponent(issueId)}`,
        params: { user }
    });

    return dispatch(userWorkLogDeleted({ issueId }));
};

export default deleteUserWorkLog;
