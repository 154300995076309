import { userWorkLogDeletedByQuery } from '../../../reducers/reports';
import { UserWorkLog, WorkLogQuery } from '../../../types/apiDomain';
import { del } from '../../request';
import createWorkLogQuery from '../../../utils/createWorkLogQuery';

const deleteUserWorkLogByQuery = (workLog: UserWorkLog, user?: string) => async (dispatch) => {
    const workLogQuery: WorkLogQuery = createWorkLogQuery(workLog);

    await del<UserWorkLog>({
        url: `/api/report/worklogs`,
        params: { user },
        data: workLogQuery
    });

    return dispatch(userWorkLogDeletedByQuery({ workLog }));
};

export default deleteUserWorkLogByQuery;
