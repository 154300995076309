import request from '../../request';
import { User } from '../../../types/apiDomain';
import { employeeListLoader } from '../../../reducers/dictionaries';

export default () => (dispatch) =>
    request<User[]>({
        url: '/api/lookup/employees',
        params: {
            employed: true
        }
    }).then((EmployeeList) => {
        return dispatch(employeeListLoader({ list: EmployeeList }));
    });
