import request from '../request';
import { exportLogsLoaded } from '../../reducers/exports';
import { ExportLogs } from '../../types/apiDomain';

export default () => (dispatch) => {
    request<ExportLogs[]>({
        url: `/api/export/logs`
    }).then((exportsLogs) => {
        return dispatch(exportLogsLoaded({ exports: exportsLogs }));
    });
};
