import { IssueTemplateEntity, IssueTemplateVariablesInfo } from '../types/apiDomain';
import { actionCreator } from './helpers';
import { reducerWithInitialState } from 'typescript-fsa-reducers';

export interface IssueTemplatesState {
    list?: IssueTemplateEntity[];
    variablesInfo?: IssueTemplateVariablesInfo;
}

const initialState: IssueTemplatesState = {};

export const issueTemplatesLoaded = actionCreator<{ list: IssueTemplateEntity[] }>('ISSUE_TEMPLATES_LOADED');
export const issueTemplateCreated = actionCreator<{ template: IssueTemplateEntity }>('ISSUE_TEMPLATES_CREATED');
export const issueTemplateEdited = actionCreator<{
    templateId: IssueTemplateEntity['id'];
    editedTemplate: IssueTemplateEntity;
}>('ISSUE_TEMPLATES_EDITED');
export const issueTemplateDeleted = actionCreator<{ templateId: IssueTemplateEntity['id'] }>('ISSUE_TEMPLATES_DELETED');
export const issueTemplateVariablesInfoLoaded = actionCreator<{ variablesInfo: IssueTemplatesState['variablesInfo'] }>(
    'ISSUE_TEMPLATE_VARIABLES_INFO_LOADED'
);

const issueTemplates = reducerWithInitialState<IssueTemplatesState>(initialState)
    .case(issueTemplatesLoaded, (state, { list }) => ({
        ...state,
        list
    }))
    .case(issueTemplateCreated, ({ list, ...state }, { template }) => ({
        ...state,
        list: list ? [...list, template] : undefined
    }))
    .case(issueTemplateEdited, ({ list, ...state }, { templateId, editedTemplate }) => ({
        ...state,
        list: list ? list.map((template) => (template.id !== templateId ? template : editedTemplate)) : undefined
    }))
    .case(issueTemplateDeleted, ({ list, ...state }, { templateId }) => ({
        ...state,
        list: list ? list.filter((template) => template.id !== templateId) : undefined
    }))
    .case(issueTemplateVariablesInfoLoaded, (state, { variablesInfo }) => ({
        ...state,
        variablesInfo
    }));

export default issueTemplates;
