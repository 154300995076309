import React, { FunctionComponent } from 'react';
import { API_HOST } from '../../constants/env';

interface Props {
    fileName: string;
}

const FileLink: FunctionComponent<Props> = ({ fileName }) => (
    <a href={`${API_HOST}/api/download?name=${fileName}`} target="_blank" rel="noopener noreferrer">
        {fileName}
    </a>
);

export default FileLink;
