import * as React from 'react';
import { PureComponent, ReactNode } from 'react';
import styles from './Page.module.css';
import { connect } from 'react-redux';

import NavMenu from '../../components/navMenu/NavMenu';
import getEmployeeList from '../../actions/api/lookup/getEmployeeList';
import getSourceList from '../../actions/api/lookup/getSourceList';

type Props = {
    path: string;
    params: any;
    getEmployeeListAction();
    getSourceListAction();
    children?: ReactNode;
};

export class Page extends PureComponent<Props> {
    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.getEmployeeListAction();
        this.props.getSourceListAction();
    }

    render() {
        const { children } = this.props;

        return (
            <div className={styles.wrapper}>
                <NavMenu />
                <div className={styles.content}>{children}</div>
            </div>
        );
    }
}

const mapActions = (dispatch) => ({
    getEmployeeListAction: () => Promise.all([dispatch(getEmployeeList())]),
    getSourceListAction: () => Promise.all([dispatch(getSourceList())])
});

export default connect(null, mapActions)(Page);
