import React, { FunctionComponent, useMemo, useState } from 'react';
import { Table } from 'antd';
import { amountSort, descSort, idSort, worklogDateSort } from '../../../utils/reportSorting';
import { getRowKey } from '../../../utils/reportUtils';
import { IssueStats, ProjectStats } from '../../../types/apiDomain';
import { getReportByEmployeesWorklogs } from './ReportByEmployeesWorklogs';
import { useCustomExpandColumn } from '../../../hooks/useCustomExpandColumn';
import styles from '../../reportByEmployeesCalendar/ReportByEmployeesCalendar.module.scss';
import classNames from 'classnames';

const { Column } = Table;

interface Props {
    entry: ProjectStats;
}

export function getReportByEmployeesIssues(entry: ProjectStats) {
    return <ReportByEmployeesIssues entry={entry} />;
}

const ReportByEmployeesIssues: FunctionComponent<Props> = ({ entry }) => {
    const issues = useMemo<IssueStats[]>(() => [...entry.issues].sort(worklogDateSort), [entry]);

    const [expandedKeys, setExpandedKeys] = useState<string[]>([]);

    const allAvailableKeys = useMemo<string[]>(() => issues.map(getRowKey) ?? [], [entry]);

    const expandColumn = useCustomExpandColumn(issues, allAvailableKeys, expandedKeys, setExpandedKeys);

    return (
        <div className={classNames(styles.nestedTableIndent, 'ant-table-wrapper')}>
            <Table
                dataSource={issues}
                pagination={false}
                rowKey={getRowKey}
                expandedRowRender={getReportByEmployeesWorklogs}
                expandRowByClick
                expandedRowKeys={expandedKeys}
                expandIconAsCell={false}
                expandIconColumnIndex={-1}
            >
                {expandColumn}
                <Column title={'id'} dataIndex={'id'} key={'id'} sorter={idSort} />
                <Column
                    title={'desc'}
                    dataIndex={'desc'}
                    key={'desc'}
                    render={(desc, record: IssueStats) => {
                        // eslint-disable-next-line react/jsx-no-target-blank
                        return (
                            <a target="_blank" rel="noopener noreferrer" href={record.url}>
                                {desc}
                            </a>
                        );
                    }}
                    sorter={descSort}
                />
                <Column title={'amount'} dataIndex={'amount'} key={'amount'} sorter={amountSort} />
                <Column
                    title={'qualityMarkers'}
                    dataIndex={'qualityMarkers'}
                    key={'qualityMarkers'}
                    render={(qualityMarkers) => {
                        return <span>{qualityMarkers.join(', ')}</span>;
                    }}
                />
            </Table>
        </div>
    );
};

export default ReportByEmployeesIssues;
