import { userWorkLogEdited } from '../../../reducers/reports';
import { UserWorkLog, UserWorkLogEdit } from '../../../types/apiDomain';
import { put } from '../../request';

const editUserWorkLog = (workLogEdit: UserWorkLogEdit, issueId: string, user?: string) => async (dispatch) => {
    const workLog = await put<UserWorkLog>({
        url: `/api/report/worklogs/${issueId}`,
        data: workLogEdit,
        params: { user }
    });

    return dispatch(userWorkLogEdited({ workLog }));
};

export default editUserWorkLog;
