import confirm from 'antd/lib/modal/confirm';

const confirmWorkLogDelete = (onOk: () => any) =>
    confirm({
        title: 'Удалить выбранное списание?',
        okText: 'Удалить',
        okType: 'danger',
        cancelText: 'Отмена',
        onOk: onOk,
        centered: true
    });

export default confirmWorkLogDelete;
