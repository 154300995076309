import isAxiosError from './isAxiosError';

const getErrorMessage = (error: any, defaultMessage?: string): string => {
    const defaultError = defaultMessage ?? 'Произошла ошибка';

    if (typeof error === 'string') {
        return error || defaultError;
    } else if (isAxiosError(error)) {
        return error.response?.data?.message ?? error.message ?? defaultError;
    } else if (error instanceof Error) {
        return error.message ?? defaultError;
    } else {
        return defaultError;
    }
};

export default getErrorMessage;
