import React, { useCallback } from 'react';
import { SelectProps } from 'antd/lib/select';
import { AppState } from '../../reducers/rootReducer';
import DataSelect from '../dataSelect/DataSelect';
import getEmployeeList from '../../actions/api/lookup/getEmployeeList';
import { User } from '../../types/apiDomain';
import { useAppSelector } from '../../hooks/reactRedux';

const EmployeeSelect = (props: SelectProps) => {
    const data = useAppSelector((state: AppState) => state.dictionaries.employeeList);
    const valueExtractor = useCallback((item: User) => item.username, []);
    const descriptionExtractor = useCallback(({ firstName, lastName }: User) => `${firstName} ${lastName}`, []);
    const fetchData = useCallback(getEmployeeList(), []);

    return (
        <DataSelect
            data={data}
            valueExtractor={valueExtractor}
            descriptionExtractor={descriptionExtractor}
            fetchData={fetchData}
            {...props}
        />
    );
};

export default EmployeeSelect;
