import { userWorkLogCreated } from '../../../reducers/reports';
import { UserWorkLog, UserWorkLogEdit } from '../../../types/apiDomain';
import { post } from '../../request';

const createUserWorkLog = (workLogEdit: UserWorkLogEdit, user?: string) => async (dispatch) => {
    const workLog = await post<UserWorkLog>({
        url: '/api/report/worklogs',
        data: workLogEdit,
        params: { user }
    });

    return dispatch(userWorkLogCreated({ workLog }));
};

export default createUserWorkLog;
