import { ExportLogs } from '../../../../../types/apiDomain';
import ExportLogType from '../types/ExportLogType';
import ResponseCode from '../../../../../utils/ResponseCode';

const getExportLogType = ({ responseCode }: ExportLogs): ExportLogType => {
    if (ResponseCode.is2XX(responseCode)) {
        return ExportLogType.SUCCESS;
    }

    if (ResponseCode.isError(responseCode)) {
        return ExportLogType.ERROR;
    }

    return ExportLogType.UNKNOWN;
};

export default getExportLogType;
