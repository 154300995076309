import React, { ComponentType, FunctionComponent, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Table } from 'antd';
import { EmployeeReport, User } from '../../types/apiDomain';
import { AppState } from '../../reducers/rootReducer';
import styles from './ReportByEmployeesTable.module.scss';
import { amountSort, colorSort, nameSort, qualitySort } from '../../utils/reportSorting';
import { getRowKey } from '../../utils/reportUtils';
import { useCustomExpandColumn } from '../../hooks/useCustomExpandColumn';
import { getReportByEmployeesProjects } from './components/ReportByEmployeesProjects';

const { Column } = Table;

interface Props {
    tableData: EmployeeReport;

    projectList: string[];
    employeeList: User[];
}

interface ExpandedRow {
    key: string;
    expandedRows: ExpandedRow[];
}

const ReportByEmployeesTable: FunctionComponent<Props> = ({ tableData }) => {
    const [expandedKeys, setExpandedKeys] = useState<string[]>([]);

    const allAvailableKeys = useMemo<string[]>(() => tableData.employees.map(getRowKey) ?? [], [tableData]);

    const expandColumn = useCustomExpandColumn(tableData?.employees, allAvailableKeys, expandedKeys, setExpandedKeys);

    return (
        <Table
            className={'components-table-demo-nested'}
            dataSource={tableData?.employees}
            rowKey={getRowKey}
            pagination={false}
            expandedRowRender={getReportByEmployeesProjects}
            expandRowByClick
            expandedRowKeys={expandedKeys}
            expandIconAsCell={false}
            expandIconColumnIndex={-1}
        >
            {expandColumn}
            <Column
                title={'status'}
                dataIndex={'status'}
                key={'status'}
                sorter={colorSort}
                render={(status) => <div className={styles.colorDot} style={{ backgroundColor: status }} />}
                width={90}
            />
            <Column title={'id'} dataIndex={'name'} key={'name'} sorter={nameSort} />
            <Column title={'firstName'} dataIndex={'firstName'} key={'firstName'} sorter={nameSort} />
            <Column title={'lastName'} dataIndex={'lastName'} key={'lastName'} sorter={nameSort} />
            <Column title={'amount'} dataIndex={'amount'} key={'amount'} sorter={amountSort} />
            <Column title={'qualityIndex'} dataIndex={'qualityIndex'} key={'qualityIndex'} sorter={qualitySort} />
        </Table>
    );
};

const mapState = (state: AppState) => {
    return {
        projectList: state.dictionaries.projectList,
        employeeList: state.dictionaries.employeeList
    };
};

export default (connect(mapState, null)(ReportByEmployeesTable) as unknown) as ComponentType<Partial<Props>>;
