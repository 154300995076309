import React, { FunctionComponent } from 'react';
import { get } from 'lodash';
import { parse } from 'qs';
import { Redirect, Route, Switch } from 'react-router';
import routes, { routeList } from './constants/routes';
import NotFoundPage from './pages/notFoundPage/NotFoundPage';
import Page from './layout/page/Page';
import useAuthSelector from './hooks/useAuthSelector';
import Restricted from './layout/restricted/Restricted';

const AppRouter: FunctionComponent = () => {
    const { user } = useAuthSelector();
    if (!user) {
        return null;
    }

    return (
        <Switch>
            {routeList.map(({ path, component: Component, roles }) => (
                <Route key={path} path={path} exact>
                    {(props) => {
                        const params = get(props, ['match', 'params']);
                        const queryParams = parse(get(props, ['location', 'search'], '').replace('?', ''));

                        return (
                            <Restricted roles={roles} forbidden={<Redirect to={routes.root.path} />}>
                                <Page path={path} params={props.match?.params}>
                                    <Component params={params} queryParams={queryParams} />
                                </Page>
                            </Restricted>
                        );
                    }}
                </Route>
            ))}
            <Route component={NotFoundPage} />
        </Switch>
    );
};

export default AppRouter;
