import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Button, Form, DatePicker, notification, ConfigProvider, Tabs } from 'antd';
import styles from './ExportPage.module.css';
import exports, { ExportsParams } from '../../actions/api/exports';
import { FormComponentProps } from 'antd/lib/form/Form';
import ru_RU from 'antd/lib/locale-provider/ru_RU';
import moment from 'moment';
import { AppState } from '../../reducers/rootReducer';
import getExportLogs from '../../actions/api/getExportLogs';
import { ExportLogs, ExportLogsCallback } from '../../types/apiDomain';
import getExportLogsCallback from '../../actions/api/getExportLogsCallback';
import ExportLogsTable from '../../components/exportLogsTable/ExportLogsTable';
import ExportLogsCallbackTable from '../../components/exportLogsCallbackTable/ExportLogsCallbackTable';
import AppPageHeader from '../../components/appPageHeader/AppPageHeader';
import { RU_DATE_FORMAT } from '../../constants/dateFormat';

interface State {
    hasError?: boolean;
    isLoading?: boolean;
}

interface Props extends FormComponentProps {
    exportLogs?: ExportLogs[];
    exportLogsCallback?: ExportLogsCallback[];
    getExportLogsAction(): Promise<any>;
    getExportLogsCallbackAction(): Promise<any>;
}

function hasErrors(fieldsError) {
    return Object.keys(fieldsError).some((field) => fieldsError[field]);
}

const today = moment();
const { TabPane } = Tabs;

class ExportPage extends PureComponent<Props, State> {
    state: State = {
        isLoading: false
    };

    componentDidMount() {
        this.getLogs();
        this.getExportLogsCallback();
    }

    submitForm = (date: ExportsParams) => {
        return exports(date)
            .then(() => {
                notification.success({
                    message: 'Экспорт ок'
                });
            })
            .catch((error) => {
                console.error(error);
                notification.error({
                    message: 'Произошла ошибка'
                });
            });
    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, fieldsValue) => {
            if (err) {
                return;
            }

            this.setState({ isLoading: true });

            const date = fieldsValue['exports'] ? fieldsValue['exports'].format('DD.MM.YYYY') : '';
            this.submitForm(date).finally(() => {
                this.setState({ isLoading: false });
            });
        });
    };

    getLogs = () => {
        const { getExportLogsAction } = this.props;

        getExportLogsAction().catch((error) => {
            console.error(error);
            notification.error({
                message: 'Произошла ошибка'
            });
        });
    };

    getExportLogsCallback = () => {
        const { getExportLogsCallbackAction } = this.props;

        getExportLogsCallbackAction().catch((error) => {
            console.error(error);
            notification.error({
                message: 'Произошла ошибка'
            });
        });
    };

    render() {
        const { getFieldDecorator, getFieldsError } = this.props.form;
        const { exportLogs, exportLogsCallback } = this.props;
        const { isLoading } = this.state;

        const formItemLayout = {
            labelCol: { span: 6 },
            wrapperCol: { span: 6 }
        };

        const buttonItemLayout = {
            wrapperCol: { span: 6, offset: 6 }
        };

        const datePickerConfig = {
            rules: [{ type: 'object', required: true, message: 'Укажите дату' }],
            initialValue: moment(today, RU_DATE_FORMAT)
        };

        return (
            <div className={styles.wrapper}>
                <AppPageHeader title="Экспорт" />
                <Tabs type="card">
                    <TabPane tab="Экспорт" key="1">
                        <Form {...formItemLayout} onSubmit={this.handleSubmit}>
                            <Form.Item label="Период">
                                <ConfigProvider locale={ru_RU}>
                                    {getFieldDecorator('exports', datePickerConfig)(<DatePicker />)}
                                </ConfigProvider>
                            </Form.Item>
                            <Form.Item {...buttonItemLayout}>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    disabled={hasErrors(getFieldsError())}
                                    loading={isLoading}
                                >
                                    Экспорт
                                </Button>
                            </Form.Item>
                        </Form>
                    </TabPane>
                    <TabPane tab="Экспорт логи" key="2">
                        <ExportLogsTable data={exportLogs!} />
                    </TabPane>
                    <TabPane tab="Экспорт лог-колбэк" key="3">
                        <ExportLogsCallbackTable data={exportLogsCallback!} />
                    </TabPane>
                </Tabs>
            </div>
        );
    }
}

const Export = Form.create<Props>({ name: 'export' })(ExportPage);

const mapState = (state: AppState) => {
    return {
        exportLogs: state.exports.exportLogs,
        exportLogsCallback: state.exports.exportLogsCallback
    };
};

const mapActions = (dispatch) => ({
    getExportLogsAction: () => Promise.all([dispatch(getExportLogs())]),
    getExportLogsCallbackAction: () => Promise.all([dispatch(getExportLogsCallback())])
});

export default connect(mapState, mapActions)(Export);
