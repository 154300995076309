import getErrorMessage from '../utils/getErrorMessage';
import isAuthorizationError from '../utils/isAuthorizationError';

interface ApiError {
    error: string;
    isAuthError: boolean;
}

export default ApiError;

export const createApiError = (error): ApiError => ({
    error: getErrorMessage(error),
    isAuthError: isAuthorizationError(error)
});
