import { isNil } from 'lodash';
import Nil from './common/Nil';

enum BoolEnum {
    FALSE = 0,
    TRUE = 1
}

export default BoolEnum;

export const booleanFromNumber = (value: BoolEnum | number | Nil): boolean | undefined => {
    return isNil(value) ? undefined : Boolean(value);
};
