import React, { FunctionComponent, useState } from 'react';
import { Button, Form, Input } from 'antd';
import styles from './JiraPasswordResetPage.module.css';
import { ANTD_COLS } from '../../constants/markup';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import useAuthSelector from '../../hooks/useAuthSelector';
import AppPage from '../../layout/appPage/AppPage';
import { PasswordResetRequest } from '../../types/apiDomain';
import requestPasswordReset from '../../actions/api/jira/requestPasswordReset';
import getErrorMessage from '../../utils/getErrorMessage';
import appNotification from '../../utils/ui/appNotification';
import displayErrorNotification from '../../utils/ui/displayErrorNotification';

const LABEL_COL = 5;

interface Props {}

interface InternalProps extends Props {
    form: WrappedFormUtils;
}

const JiraPasswordResetPage: FunctionComponent<InternalProps> = ({ form }) => {
    const { user } = useAuthSelector();

    const { getFieldDecorator, validateFields } = form;

    const [isRequestLoading, setIsRequestLoading] = useState<boolean>(false);

    const handleFormSubmit = (event: React.FormEvent) => {
        event.preventDefault();

        validateFields(async (errors, values) => {
            if (errors) {
                return;
            }

            const requestData = values as PasswordResetRequest;

            setIsRequestLoading(true);
            try {
                const response = await requestPasswordReset(requestData);

                const createdTaskKey = response.response?.creation?.key;
                const creationMessage = createdTaskKey ? `Задача ${createdTaskKey} успешно создана` : response.message;

                appNotification.success({
                    message: 'Запрос создан',
                    description: (
                        <div>
                            <p>{creationMessage}</p>
                            <p>
                                <a href={response.issueUrl} target="_blank" rel="noopener noreferrer">
                                    {response.issueUrl}
                                </a>
                            </p>
                        </div>
                    ),
                    duration: 0
                });
            } catch (error) {
                displayErrorNotification(getErrorMessage(error), 'Произошла ошибка при запросе');
            } finally {
                setIsRequestLoading(false);
            }
        });
    };

    return (
        <AppPage title="Сброс пароля Jira">
            <Form
                className={styles.form}
                labelCol={{ span: LABEL_COL }}
                wrapperCol={{ span: ANTD_COLS - LABEL_COL }}
                onSubmit={handleFormSubmit}
            >
                <Form.Item label="Логин">
                    {getFieldDecorator('login', {
                        rules: [
                            {
                                required: true,
                                whitespace: true,
                                message: 'Укажите логин в Jira'
                            }
                        ]
                    })(<Input type="text" placeholder="Логин в Jira" readOnly={isRequestLoading} />)}
                </Form.Item>
                <Form.Item label="Email">
                    {getFieldDecorator('email', {
                        rules: [
                            {
                                required: true,
                                whitespace: true,
                                message: 'Укажите email'
                            },
                            {
                                type: 'email',
                                message: 'Введите корректный email'
                            }
                        ],
                        initialValue: user?.email
                    })(<Input type="email" placeholder="Email" readOnly={isRequestLoading} />)}
                </Form.Item>
                <Form.Item wrapperCol={{ span: ANTD_COLS - LABEL_COL, offset: LABEL_COL }}>
                    <Button type="primary" htmlType="submit" loading={isRequestLoading}>
                        Запросить
                    </Button>
                </Form.Item>
            </Form>
        </AppPage>
    );
};

export default (Form.create()(JiraPasswordResetPage) as unknown) as FunctionComponent<Props>;
