import React, { FunctionComponent, HTMLProps } from 'react';
import AppPageHeader from '../../components/appPageHeader/AppPageHeader';
import styles from './AppPage.module.css';
import classNames from 'classnames';
import { PageHeaderProps } from 'antd/lib/page-header';

type RootProps = HTMLProps<HTMLDivElement>;

interface Props extends Omit<RootProps, 'title'> {
    title: PageHeaderProps['title'];
    htmlTitle?: RootProps['title'];
    headerProps?: Omit<PageHeaderProps, 'title'>;
}

const AppPage: FunctionComponent<Props> = ({ title, htmlTitle, className, children, ...props }) => (
    <div className={classNames(styles.container, className)} {...props} title={htmlTitle}>
        <AppPageHeader title={title} />
        {children}
    </div>
);

export default AppPage;
