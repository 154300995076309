import moment, { Moment } from 'moment';

class ImmutableMoment {
    private readonly _moment: Moment;

    constructor(moment: Moment) {
        this._moment = moment;
    }

    get moment() {
        return this._moment;
    }

    set(unit: moment.unitOfTime.All, value: number): ImmutableMoment {
        return new ImmutableMoment(this.moment.clone().set(unit, value));
    }

    add(unit: moment.DurationInputArg2, amount: moment.DurationInputArg1): ImmutableMoment {
        return new ImmutableMoment(this.moment.add(amount, unit));
    }
}

export default ImmutableMoment;
