import React, { PureComponent } from 'react';
import { notification } from 'antd';

import importTasks, { ImportTasksParams } from '../../actions/api/importTasks';

import ImportTasksForm from '../../forms/importTasksForms/ImportTasksForm';

import styles from './ImportPage.module.css';
import AppPageHeader from '../../components/appPageHeader/AppPageHeader';

interface State {
    hasError?: boolean;
}

export default class ImportPage extends PureComponent<{}, State> {
    state: State = {};

    handleSubmit = (params: ImportTasksParams) => {
        return importTasks(params)
            .then(() => {
                notification.success({
                    message: 'Импорт завершён'
                });
            })
            .catch(() => {
                notification.error({
                    message: 'Произошла ошибка при импорте'
                });
            });
    };

    render() {
        return (
            <div className={styles.wrapper}>
                <AppPageHeader title="Импорт" />
                <ImportTasksForm submitForm={this.handleSubmit} />
            </div>
        );
    }
}
