import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import { Menu, Icon } from 'antd';
import { Location } from 'history';
import styles from './NavMenu.module.css';
import routes, { AppRoute } from '../../constants/routes';
import { userHasRoles } from '../../layout/restricted/Restricted';
import useAuthSelector from '../../hooks/useAuthSelector';
import { getRootRoute } from '../../pages/rootPage/RootPage';

interface RouteLink {
    route: AppRoute;
    title: string;
}

const links: RouteLink[] = [
    {
        route: routes.reportByEmployees,
        title: 'Отчет по сотрудникам'
    },
    {
        route: routes.reportByProjects,
        title: 'Отчет по проектам'
    },
    {
        route: routes.import,
        title: 'Импорт'
    },
    {
        route: routes.export,
        title: 'Экспорт'
    },
    {
        route: routes.vacation,
        title: 'Отчет по отпускным дням'
    },
    {
        route: routes.issueTemplate,
        title: 'Шаблоны задач'
    },
    {
        route: routes.jiraPasswordReset,
        title: 'Сброс пароля Jira'
    },
    {
        route: routes.activities,
        title: 'Списания'
    }
];

const NavMenu: FunctionComponent = () => {
    const { user } = useAuthSelector();
    const rootRoute = useMemo(() => getRootRoute(user), [user]);
    const allowedLinks = useMemo(() => links.filter(({ route }) => userHasRoles(user, route.roles)), [user]);

    const isLinkActive = useCallback(
        (path: string) => {
            return (match, location: Location<unknown>): boolean => {
                return location.pathname === path || (path === rootRoute?.path && location.pathname === '/');
            };
        },
        [rootRoute]
    );

    return (
        <Menu style={{ width: 256, minHeight: '100%' }} mode="vertical">
            {/* Menu.Item'ы работают тут криво, если использовать <Restricted/> */}
            {allowedLinks.map(({ route: { path }, title }) => (
                <Menu.Item key={path} className={styles.menuItem}>
                    <NavLink
                        to={path}
                        isActive={isLinkActive(path)}
                        className={styles.navLink}
                        activeClassName={styles.activeItem}
                    >
                        <Icon type="table" />
                        {title}
                    </NavLink>
                </Menu.Item>
            ))}
        </Menu>
    );
};

export default NavMenu;
