import { ExportLogsCallback } from '../../../../types/apiDomain';
import ExportLogCallbackType from '../types/ExportLogCallbackType';

const getExportLogCallbackType = (logCallback: ExportLogsCallback): ExportLogCallbackType => {
    if ((logCallback.data as any)?.logs?.length) {
        return ExportLogCallbackType.HAS_ERRORS;
    }

    return ExportLogCallbackType.SUCCESSFUL;
};

export default getExportLogCallbackType;
