import { ExportLogs } from '../../../../../types/apiDomain';
import ExportLogFilter from '../types/ExportLogFilter';
import QueryMatching from '../../../../../utils/QueryMatching';
import { every, isEmpty, isString, values } from 'lodash';
import getExportLogType from './getExportLogType';
import moment from 'moment';

const logMatchesFilter = (log: ExportLogs, filter: ExportLogFilter): boolean => {
    const matches: Record<string, boolean> = {
        byUuid: QueryMatching.matchString(log.uuid, filter.uuid),
        byDate: QueryMatching.matchDateRange(moment(log.date), filter.date),
        byResponseCode: QueryMatching.matchNumber(log.responseCode, filter.responseCode),
        byCallbackUrl: QueryMatching.matchString(log.sentData.callbackUrl, filter.callbackUrl),
        byResponse: isString(log.responseData)
            ? QueryMatching.matchString(log.responseData, filter.response)
            : !filter.response?.trim(),
        byType: QueryMatching.matchNumber(getExportLogType(log), filter.type),
        byHasData: QueryMatching.matchNumericBoolean(log.sentData.items.length > 0, filter.hasData),
        byHasResponse: QueryMatching.matchNumericBoolean(!isEmpty(log.responseData), filter.hasResponse)
    };

    return every(values(matches));
};

export default logMatchesFilter;
