import getErrorMessage from '../getErrorMessage';
import { notification } from 'antd';

const displayErrorNotification = (error: any, title?: string) => {
    const errorMessage = getErrorMessage(error);
    notification.error({
        placement: 'bottomLeft',
        message: title ?? 'Произошла ошибка',
        description: errorMessage
    });
};

export default displayErrorNotification;
