import EditMode from './EditMode';
import { UserWorkLog } from '../../../types/apiDomain';
import ActivityEditFormObject, {
    createActivityEditFormObject
} from '../components/activityEditForm/types/ActivityEditFormObject';

export interface EditingWorkLog {
    originalObject: UserWorkLog;
    editedObject: ActivityEditFormObject;
}

export const createEditingWorkLog = (originalObject: UserWorkLog): EditingWorkLog => ({
    originalObject,
    editedObject: createActivityEditFormObject(originalObject)
});

interface EditFormState {
    mode: EditMode;
    addingItem?: ActivityEditFormObject;
    editingItem?: EditingWorkLog;
}

export default EditFormState;
