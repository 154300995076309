import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { ExportLogsCallback } from '../../types/apiDomain';
import { Table } from 'antd';
import { compareDates, compareFields, compareNumbers, compareStrings } from '../../utils/sorting';
import { PaginationConfig } from 'antd/lib/pagination';
import moment from 'moment';
import { DATETIME_HR_FORMAT } from '../../constants/dateFormat';
import styles from './ExportLogsCallbackTable.module.scss';
import ExportLogCallbackFilter from './exportLogsCallbackFilter/types/ExportLogCallbackFilter';
import ExportLogsCallbackFilter from './exportLogsCallbackFilter/ExportLogsCallbackFilter';
import logCallbackMatchesFilter from './exportLogsCallbackFilter/utils/logCallbackMatchesFilter';

const { Column } = Table;

interface Props {
    data: ExportLogsCallback[];
    loading?: boolean;
}

const rootPagination: PaginationConfig = {
    pageSize: 12
};

const subPagination: PaginationConfig = {
    hideOnSinglePage: true
};

const getRowClassName = (record) => {
    if (record.data?.logs?.length) {
        return styles.tableRowError;
    }

    return '';
};

const ExportLogCallbackTable: FunctionComponent<Props> = ({ data }) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [filter, setFilter] = useState<ExportLogCallbackFilter>({});

    const filteredData = useMemo<ExportLogsCallback[] | undefined>(() => {
        if (!data?.length) {
            return data;
        }

        return data.filter((logCallback) => logCallbackMatchesFilter(logCallback, filter));
    }, [data, filter]);

    useEffect(() => {
        if (isLoading && data) {
            setIsLoading(false);
        }
    }, [data, isLoading]);

    const getRowKey = (key?) => (entry, index: number) => {
        return `row_key_${key ? entry[key] : index}`;
    };

    const expandedSendDataItemRowRender = (entry) => {
        return (
            <Table dataSource={entry.data.logs} pagination={subPagination} rowKey={getRowKey()}>
                <Column title="ID" dataIndex="id" key="id" />
                <Column title="Код ошибки" dataIndex="errorCode" key="errorCode" />
                <Column title="Текст ошибки" dataIndex="errorText" key="errorText" />
            </Table>
        );
    };

    return (
        <div>
            <ExportLogsCallbackFilter onFilter={setFilter} />

            <Table
                className="components-table-demo-nested"
                dataSource={filteredData}
                rowKey={getRowKey('uuid')}
                pagination={rootPagination}
                expandedRowRender={expandedSendDataItemRowRender}
                expandRowByClick
                loading={isLoading}
                rowClassName={getRowClassName}
            >
                <Column title="UUID" dataIndex="uuid" key="uuid" sorter={compareFields('uuid', compareStrings)} />
                <Column
                    title="Дата"
                    dataIndex="date"
                    key="date"
                    render={(date) => <>{moment(date).format(DATETIME_HR_FORMAT)}</>}
                    sorter={compareFields('date', compareDates)}
                />
                <Column
                    title="Ошибок"
                    key="count"
                    className="text-nowrap"
                    render={(data) => <>{data.data.logs.length}</>}
                    sorter={compareFields('data.logs.length', compareNumbers)}
                />
                <Column
                    title="Accepted Hours"
                    dataIndex="data"
                    key="acceptedHoursData"
                    render={(data) => <>{data.acceptedHours}</>}
                    sorter={compareFields('acceptedHoursData.acceptedHours', compareNumbers)}
                />
                <Column
                    title="Received Hours"
                    dataIndex="data"
                    key="receivedHoursData"
                    render={(data) => <>{data.receivedHours}</>}
                    sorter={compareFields('receivedHoursData.receivedHours', compareNumbers)}
                />
            </Table>
        </div>
    );
};

export default ExportLogCallbackTable;
