import React, { FunctionComponent } from 'react';
import { Table } from 'antd';
import { getRowKey } from '../../../utils/reportUtils';
import { dateSort } from '../../../utils/reportSorting';
import { IssueStats } from '../../../types/apiDomain';
import styles from '../../reportByEmployeesCalendar/ReportByEmployeesCalendar.module.scss';
import classNames from 'classnames';
import { EmployeeCalendarIssue } from '../../../types/reportTypes';

const { Column } = Table;

const getHoursFromMinutes = (minutes: number) => {
    return <span>{(minutes / 60).toFixed(1)}</span>;
};

interface Props {
    entry: IssueStats | EmployeeCalendarIssue;
}

export function getReportByEmployeesWorklogs(entry: IssueStats | EmployeeCalendarIssue) {
    return <ReportByEmployeesWorklogs entry={entry} />;
}

const ReportByEmployeesWorklogs: FunctionComponent<Props> = ({ entry }) => {
    return (
        <div className={classNames(styles.nestedTableIndent, 'ant-table-wrapper')}>
            <Table dataSource={entry.worklogs} pagination={false} rowKey={getRowKey}>
                <Column title={'date'} dataIndex={'date'} key={'date'} sorter={dateSort} />
                <Column title={'comment'} dataIndex={'comment'} key={'comment'} />
                <Column title={'hours'} dataIndex={'minutes'} key={'minutes'} render={getHoursFromMinutes} />
            </Table>
        </div>
    );
};

export default ReportByEmployeesWorklogs;
