import React, { FunctionComponent } from 'react';
import ErrorResult from '../errorResult/ErrorResult';
import { Spin } from 'antd';

interface Props {
    loading: boolean;
    error: any;
}

const Status: FunctionComponent<Props> = ({ loading, error, children }) =>
    error ? <ErrorResult error={error} /> : <Spin spinning={loading}>{children}</Spin>;

export default Status;
