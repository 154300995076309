import request from '../../request';

export interface ReportParams {
    startDate: string;
    endDate: string;
    projects?: string;
    employees?: string;
}

export default <T extends object>({ startDate, endDate, projects, employees, ...params }: ReportParams, url: string) =>
    request<T>({
        url,
        params: {
            startDate,
            endDate,
            projects: projects !== '' ? projects : undefined,
            employees,
            ...params
        }
    });
