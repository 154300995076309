import React, { FunctionComponent, ReactNode, useMemo } from 'react';
import { UserInfo, UserRole } from '../../types/apiDomain';
import useAuthSelector from '../../hooks/useAuthSelector';

export type RolesRestriction = AnyRoleRestriction | EveryRoleRestriction;

abstract class BaseRoleRestriction {
    constructor(public readonly roles: UserRole[]) {}

    abstract check(userRoles: UserRole[]): boolean;
}

class AnyRoleRestriction extends BaseRoleRestriction {
    check(userRoles: UserRole[]): boolean {
        return this.roles.some((role) => userRoles.includes(role));
    }
}

class EveryRoleRestriction extends BaseRoleRestriction {
    check(userRoles: UserRole[]): boolean {
        return this.roles.every((role) => userRoles.includes(role));
    }
}

const anyRole = (...roles: UserRole[]): RolesRestriction => new AnyRoleRestriction(roles);
const everyRole = (...roles: UserRole[]): RolesRestriction => new EveryRoleRestriction(roles);

export const userHasRoles = (user?: UserInfo, roles?: RolesRestriction): boolean => {
    if (!roles) {
        return user !== undefined;
    }

    return user?.roles !== undefined && roles.check(user.roles);
};

export const DashUserRole = {
    USER: anyRole(UserRole.USER),
    ADMIN: anyRole(UserRole.ADMIN, UserRole.DASH_ADMIN)
};

interface Props {
    roles?: RolesRestriction;
    forbidden?: ReactNode;
}

const Restricted: FunctionComponent<Props> = ({ roles, forbidden, children }) => {
    const { user } = useAuthSelector();

    const isAllowed = useMemo(() => userHasRoles(user, roles), [user, roles]);

    if (!user) {
        return null;
    }

    if (!isAllowed) {
        return forbidden !== undefined ? <>{forbidden}</> : null;
    }

    return <>{children}</>;
};

export default Restricted;
